<template>
  <el-table-column fixed="right" width="100" label="操作">
    <template #default="{ row, $index }">
        <template v-if ="$index !== 0">
          <el-button
            v-if="!row.isEdit"
            type="text"
            size="mini"
            @click="editRow(row)"
            >编辑</el-button
          >
          <div v-else>
            <el-button type="text" size="mini" @click="saveList(row)">保存</el-button>
            <el-button type="text" size="mini" @click="cancelList(row)">取消</el-button>
          </div>
        </template>
    </template>
  </el-table-column>
  <el-table-column fixed="right" width="100" label="新建">
       <template #header>
            <el-button type="primary" size="mini" @click="creatNewRow()"><i class="el-icon-plus"></i></el-button>
       </template>
  </el-table-column>
</template>
<script>
import { reactive, toRefs, watchEffect } from 'vue';
import {
  showErrorMsg,
} from '@/utils';

export default {
  name: 'cksTableOperate',
  desc: '表格操作',
  props: ['hasEditRow'],
  setup(props, { emit }) {
    const data = reactive({
      hasEditRow: false,
    });

    watchEffect(() => {
      data.hasEditRow = props.hasEditRow;
    });

    function editRow(row) {
      if (data.hasEditRow) {
        return showErrorMsg('存在编辑行没有保存或取消');
      }
      row.isEdit = true;
      data.hasEditRow = true;
    }

    function saveList(row) {
      emit('save', row, () => {
        row.isEdit = false;
        row.isNewRow = false;
        data.hasEditRow = false;
      });
    }

    function creatNewRow() {
      if (data.hasEditRow) {
        return showErrorMsg('存在编辑行没有保存或取消');
      }
      data.hasEditRow = true;
      emit('creat');
    }

    function cancelList(row) {
      emit('cancel');
      row.isEdit = false;
      data.hasEditRow = false;
    }

    return {
      cancelList,
      creatNewRow,
      saveList,
      editRow,
      ...toRefs(data),
    };
  },
};
</script>
