<template>
  <cks-page-module>
    <div class="ckc-layout-header show-height" ref="headerRef">
      <div class="bor" ref="borRef"></div>
      <div class="logo-box" @click="pushRoute">
        <img class="hvr-grow" src="@/assets/images/home-image/logo2.png" alt="">
      </div>
      <div class="flot-right" v-if="paths[0] !=='enterprise'">
        <div class="header-nav" ref="headerNavRef" @mousemove="mousemove" @mouseleave="mouseleave">
          <div @click="goToRouter(item, index)" :class="{'active': active == index + 1}" class="nav-link"  v-for="(item, index) in tabs" :key="index">
            <img :src="item.img"/>
          </div>
        </div>
        <div class="header-phone">
          <img src="@/assets/images/home-image/page30.png" alt="">
          <span>{{ phone }}</span>
        </div>
        <el-icon @click="drawer = !drawer" class="menu-box" style="font-size: 24px">
          <Expand />
        </el-icon>

        <el-drawer size="50%" v-model="drawer" :show-close="false" title="I am the title">
          <template #title>
            <div class="nav-title">导航栏</div>
          </template>
          <div class="header-nav-menu">
            <div @click="goToRouter(item)" :class="{'active': active == index + 1}" class="nav-link"  v-for="(item, index) in tabs" :key="index">
              <span class="title">
                {{ item.title }}
                <el-icon class="nav-icon"><CaretBottom /></el-icon>
              </span>
            </div>
          </div>
        </el-drawer>
      </div>
    </div>
  </cks-page-module>
</template>

<script>
import {
  onMounted, onUnmounted, reactive, toRefs, watch, watchEffect,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { Expand, CaretBottom } from '@element-plus/icons';
import img1 from '@/assets/images/layout-header-image/page01.png';
import img2 from '@/assets/images/layout-header-image/page02.png';
import img3 from '@/assets/images/layout-header-image/page03.png';
import img4 from '@/assets/images/layout-header-image/page04.png';
import img5 from '@/assets/images/layout-header-image/page11.png';
import img6 from '@/assets/images/layout-header-image/page06.png';
import img7 from '@/assets/images/layout-header-image/page07.png';
import img8 from '@/assets/images/layout-header-image/page13.png';
import img9 from '@/assets/images/layout-header-image/page09.png';

export default {
  components: {
    CaretBottom,
    Expand,
  },
  setup() {
    const paths = window.location.pathname.split('/').filter(Boolean);
    const data = reactive({
      drawer: false,
      login: false,
      active: 1,
      paths,
      tabs: [
        {
          path: '1',
          title: '首页',
          name: 'index',
          img: img1,
        },
        {
          path: '2',
          title: '走进穗深',
          name: 'receptionEnterSpikeDepth',
          img: img2,
        },
        {
          path: '3',
          title: '冷链设计建造',
          name: 'design',
          img: img9,
        },
        {
          path: '4',
          title: '冷链智慧维保',
          name: 'smartMaintenance',
          img: img3,
        },
        {
          path: '5',
          title: '供应链服务',
          name: 'coldChain',
          img: img8,
        },
        {
          path: '6',
          title: '冷链产业发展',
          name: 'operate',
          img: img5,
        },
        {
          path: '7',
          title: '战略伙伴',
          name: 'partner',
          img: img7,
        },
        {
          path: '8',
          title: '新闻中心',
          name: 'receptionNewsCenter',
          img: img4,
        },
        {
          path: '9',
          title: '加入我们',
          name: 'receptionJoinUs',
          img: img6,
        },
      ],
      headerNavRef: null,
      borRef: null,
      activeIndex: 0,
      phone: '0755-82263776',
      headerRef: null,
    });

    const router = useRouter();
    const route = useRoute();

    watchEffect(() => {
      data.active = data.tabs.find(item => item.name === paths[0])?.path || 1;
    });

    function goToRouter(item, index) {
      const preActive = data.active;
      data.active = item.path;
      data.activeIndex = index;
      if (preActive === data.active) {
        document.getElementById('app').scrollTop = { top: 0, behavior: 'smooth' };
        return;
      }
      router.push({
        name: item.name,
      });
      data.drawer = false;
    }

    watch(() => route, newVal => {
      if (newVal) {
        const { name, meta } = newVal;
        data.phone = meta.phone || '0755-82263776';
        const index = data.tabs.findIndex(item => item.name === name);
        if (index !== -1) {
          data.borRef.style.left = `${data.headerNavRef.children[index].getBoundingClientRect().left}px`;
          data.borRef.style.width = `${data.headerNavRef.children[index].offsetWidth}px`;
          data.activeIndex = index;
          goToRouter(data.tabs[index], index);
        }
      }
    }, { deep: true });

    const pushRoute = () => {
      data.paths = ['index'];
      document.getElementById('app').scrollTop = { top: 0, behavior: 'smooth' };
      router.push({
        name: 'index',
      });
    };

    const mousemove = (e) => {
      const { target } = e;
      if (Array.prototype.includes.call(target.classList, 'nav-link')) {
        data.headerNavRef.children.forEach(item => item.classList.remove('active2'));
        target.classList.add('active2');
        const width = target.offsetWidth;
        data.borRef.style.width = `${width}px`;
        data.borRef.style.left = `${target.offsetLeft}px`;
      }
    };

    const mouseleave = () => {
      data.headerNavRef.children.forEach(item => item.classList.remove('active2'));
      const item = data.headerNavRef.children[data.activeIndex];
      const width = item.offsetWidth;
      data.borRef.style.width = `${width}px`;
      data.borRef.style.left = `${item.offsetLeft}px`;
    };

    const resize = () => {
      mouseleave();
    };

    let app = null;
    let oldTop = 0;
    const scroll = (e) => {
      const { target } = e;
      if (oldTop > target.scrollTop) {
        data.headerRef.classList.add('show-height');
      } else {
        data.headerRef.classList.remove('show-height');
      }
      oldTop = target.scrollTop;
    };

    onMounted(() => {
      const index = data.tabs.findIndex(item => item.name === route.name);
      if (index !== -1) {
        data.borRef.style.left = `${data.headerNavRef.children[index].getBoundingClientRect().left}px`;
        data.borRef.style.width = `${data.headerNavRef.children[index].offsetWidth}px`;
        data.activeIndex = index;
      }
      app = document.querySelector('#app');
      window.addEventListener('resize', resize);
      app.addEventListener('scroll', scroll);
    });

    onUnmounted(() => {
      window.removeEventListener('resize', resize);
      app.removeEventListener('scroll', scroll);
    });

    return {
      goToRouter,
      ...toRefs(data),
      pushRoute,
      mousemove,
      mouseleave,
    };
  },
};
</script>

<style lang="scss" scoped>
.ckc-layout-header {
  width: 100%;
  height: 0px;
  display: flex;
  align-items: center;
  padding-left: 46px;
  padding-right: 70px;
  font-size: var(--fontSize20);
  font-weight: 400;
  color: #FFF;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 3;
  background-image: url('~~@/assets/images/home-image/bgc.png');
  transition: height 0.5s, opacity 0.5s;
  background-position: 50% 50%;
  background-size: 100% 100%;
  overflow: hidden;
  .bor {
    height: 0.3125vw;
    background-color: #FFF;
    position: absolute;
    bottom: 0;
    width: 0px;
    z-index: 10;
    transition: all 0.5s;
  }
  .flot-right {
    display: flex;
    height: 100%;
  }
  .logo-box {
    width: 8.333vw;
    // height: 50px;
    img {
      display: block;
      width: 100%;
      // height: 100%;
    }
  }

  .header-nav {
    display: flex;
    height: 100%;
    align-items: center;
    .nav-link {
      height: 100%;
      padding: 0px 0.781vw;
      position: relative;
      display: flex;
      align-items: center;
      &:nth-child(1) {
        img {
          display: block;
          width: 1.875vw;
          height: 0.99vw;
        }
      }
      &:nth-child(2) {
        img {
          display: block;
          width: 3.906vw;
          height: 0.99vw;
        }
      }
      &:nth-child(3) {
        img {
          display: block;
          width: 6.198vw;
          height: 0.99vw;
        }
      }
      &:nth-child(4) {
        img {
          display: block;
          width: 6.198vw;
          height: 0.99vw;
        }
      }
      &:nth-child(5) {
        img {
          display: block;
          width: 6.198vw;
          height: 0.99vw;
        }
      }
      &:nth-child(6) {
        img {
          display: block;
          width: 6.198vw;
          height: 0.99vw;
        }
      }
      &:nth-child(7) {
        img {
          display: block;
          width: 4.063vw;
          height: 0.99vw;
        }
      }
      &:nth-child(8){
        img {
          display: block;
          width: 4.063vw;
          height: 0.99vw;
        }
      }
      &:nth-child(9) {
        img {
          display: block;
          width: 4.063vw;
          height: 0.99vw;
        }
      }
      .nav-icon {
        display: none;
        position: absolute;
        top: -18px;
        left: 50%;
        transform: translateX(-50%);
      }
      &:hover {
        color: #31499F;
        cursor: pointer;
      }
      &.active {
        color: var(--baseColor1);
        font-weight: 700;
        .nav-icon {
          display: block;
        }
      }
      &.active2 {
        color: var(--baseColor1);
        .nav-icon {
          display: block;
        }
      }
    }
  }
  .nav-title {
    font-size: 18px;
    text-align: center;
    height: 40px;
    line-height: 40px;
    background: #31499F;
    color: #FFFFFF;
  }
  .menu-box {
    // width: 120px;
    margin-left: 40px;
    display: none;
    &>span {
      margin-left: 4px;
    }
  }
  .header-nav-menu {
    display: none;
    .nav-link {
      height: 40px;
      line-height: 40px;
      margin-right: 0px;
      position: relative;
      padding-left: 20px;
      .title {
        position: relative;
      }
      .nav-icon {
        display: none;
        position: absolute;
        top: 50%;
        right: -30px;
        transform: translateY(-50%);
        z-index: 99;
      }
      &:hover {
        color: #31499F;
        cursor: pointer;
      }
      &.active {
        color: #31499F;
        .nav-icon {
          display: block;
        }
      }
    }
  }
  .header-phone {
    display: flex;
    align-items: center;
    margin-left: 1.979vw;
    img {
      display: block;
      width: 1.04vw;
      height: 1.04vw;
      margin-right: 6px;
    }
  }
  .header-phone.header-phone-menu {
    display: none;
  }
}
.show-height {
  height: 5.208vw;
  opacity: 1;
}
/* 当页面宽度小于768px */
@media screen and (max-width: 768px) {
  .ckc-layout-header {
    height: 8vw;
    padding-left: 2.667vw;
    padding-right: 2.667vw;
    opacity: 1;
    .logo-box {
      width: 16vw;
      // height: 40px;
      img {
        display: block;
        width: 100%;
        // height: 100%;
      }
    }
    .header-nav {
      display: none;
    }
    .nav-title {
      font-size: 2.4vw;
      text-align: center;
      height: 8vw;
      line-height: 8vw;
      background: var(--baseColor1);
      color: #FFFFFF;
    }
    .menu-box {
      display: flex;
      font-size: 18px;
      &>span {
        margin-left: 4px;
      }
    }
    .header-nav-menu {
      display: block;
      .nav-link {
        height: 40px;
        line-height: 40px;
        margin-right: 0px;
        position: relative;
        padding-left: 20px;
        color: #000;
        .title {
          position: relative;
        }
        .nav-icon {
          display: none;
          position: absolute;
          top: 50%;
          right: -30px;
          transform: translateY(-50%);
          z-index: 99;
        }
        &:hover {
          color: var(--baseColor1);
          cursor: pointer;
        }
        &.active {
          color: var(--baseColor1);
          .nav-icon {
            display: block;
          }
        }
      }
    }
    .header-phone {
      display: none;
    }
    .header-phone.header-phone-menu {
      display: flex;
      align-items: center;
      font-size: 12px;
      img {
        display: block;
        width: 12px;
        height: 12px;
        margin-right: 6px;
        object-fit: cover;
      }
    }
  }
}
</style>
