<template>
  <div style="display: flex;">
      <!-- 使用element-ui自带样式 -->
      <ul class="el-upload-list el-upload-list--picture-card">
        <draggable
          :list="fileList"
          animation="300"
          @start="onStart"
          @end="onEnd"
        >
          <template  #item="{ element }">
          <li class="el-upload-list__item is-success animated">
            <img :src="element.url" alt="" class="el-upload-list__item-thumbnail" />
            <i class="el-icon-close"></i>
            <span class="el-upload-list__item-actions">
              <span
                class="el-upload-list__item-preview"
                @click="handlePictureCardPreview(element)"
              >
                <i class="el-icon-zoom-in"></i>
              </span>
              <span
                class="el-upload-list__item-delete"
                @click="handleRemove(element)"
              >
                <i class="el-icon-delete"></i>
              </span>
            </span>
          </li>
          </template>
        </draggable>
      </ul>
      <el-upload
      v-model:file-list="fileList"
      action="action"
      :http-request="uploadFile"
      list-type="picture-card"
      :show-file-list="false"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :before-upload="beforeUpload"
      class="fdiid"
      multiple
      :class="{'active-img-list': fileList.length >= limit}"
      :limit="limit"
      v-loading="loading"
    >
      <el-icon><Plus /></el-icon>
    </el-upload>
    </div>
    <el-dialog v-model="dialogVisible" width="30%">
      <div style="width: 100%; margin: 0 auto;height:80vh;overflow:auto">
        <img style="width:100%;" :src="dialogImageUrl" alt="Preview Image" />
      </div>
    </el-dialog>
  </template>

<script>
import { reactive, toRefs, watchEffect } from 'vue';
import { Plus } from '@element-plus/icons-vue';
import apis from '@/request/apis';
import draggable from 'vuedraggable';
import { showErrorMsg } from '@/utils';

export default {
  components: {
    draggable, Plus,
  },
  props: {
    limit: {
      type: Number,
      default: 1,
    },
    fileUrl: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 1,
    },
    sizeW: {
      type: Number,
      default: -1000,
    },
    sizeH: {
      type: Number,
      default: -1000,
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      loading: false,
    });
    const step = (timer = 500) => new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, timer);
    });
    function handleRemove(val) {
      data.fileList = data.fileList.filter(item => item.url !== val.url);
      const url = data.fileList.map(item => item.url).join(',');
      emit('updateUrl', url);
    }
    function handlePictureCardPreview(val) {
      data.dialogImageUrl = val.url;
      data.dialogVisible = true;
    }

    const beforeUpload = (file) => new Promise((resolve, reject) => {
      const { size, name } = file;
      const typeImg = [
        'jpg',
        'jpeg',
        'png',
        'gif',
      ];
      const type = name.split('.')[name.split('.').length - 1];
      if (!typeImg.includes(type)) {
        showErrorMsg('图片格式支持.png, .jpg, .gif');
        reject();
        return false;
      }
      if (size > 1024 * 1024 * props.size) {
        showErrorMsg(`大小不能超过${props.size}M`);
        reject();
        return false;
      }
      const { sizeW, sizeH } = props;
      if (sizeW > 0 && sizeH > 0) {
        const blob = new Blob([file]);
        const url = window.URL.createObjectURL(blob);
        const img = document.createElement('img');
        img.src = url;
        img.style.position = 'absolute';
        img.style.right = '-3000px';
        document.body.appendChild(img);
        step(300).then(() => {
          const w = img.offsetWidth;
          const h = img.offsetHeight;
          document.body.removeChild(img);
          const num = 20;
          if ((w > (sizeW + num)) || (h > (sizeH + num)) || (w < (sizeW - num)) || (h < (sizeH - num))) {
            showErrorMsg(`图片尺寸必须为1920px * 1080px; 上下浮动不超过${num}px`);
            reject();
            return false;
          }
        });
      }
      step(500).then(() => {
        resolve();
      });
    });
    const uploadFile = async (val) => {
      const { file } = val;
      try {
        data.loading = true;
        const fd = new FormData();
        fd.append('file', file);
        const res = await apis.upload.uploadFile(fd);
        data.fileList.push({
          url: res.filePath,
          name: res.fileName,
        });
        const url = data.fileList.map(item => item.url).join(',');
        emit('updateUrl', url);
      } catch (err) {

      } finally {
        data.loading = false;
      }
    };

    async function onEnd() {
      const url = data.fileList.map(item => item.url).join(',');
      emit('updateUrl', url);
    }

    watchEffect(() => {
      if (props.fileUrl) {
        data.fileList = [];
        props.fileUrl.split(',').forEach(item => {
          data.fileList.push({
            url: item,
            name: item,
          });
        });
      }
    });
    return {
      ...toRefs(data),
      handleRemove,
      handlePictureCardPreview,
      uploadFile,
      onEnd,
      beforeUpload,
    };
  },
};
</script>

  <style scoped>
  ::v-deep.active-img-list .el-upload {
    display: none;
  }
  ::v-deep .el-upload--picture-card:hover {
    border: 1px dashed #2F51FF;
  }
  </style>
