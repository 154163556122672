<template>
  <div class="layout">
    <layout-header-v2></layout-header-v2>
    <div>
      <router-view v-cloak></router-view>
    </div>
    <layout-footer v-show="!flag"></layout-footer>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue';
import LayoutHeaderV2 from './layout-header-v2.vue';
import LayoutFooter from './layout-footer.vue';
import { setPageStore } from '@/utils';
import { useRoute } from 'vue-router';

export default {
  components: {
    LayoutFooter,
    LayoutHeaderV2,
  },
  setup() {
    const paths = window.location.pathname.split('/').filter(Boolean);
    const provideData = reactive({
      currentModule: paths[0],
      selected: false,
      flag: false,
    });
    setPageStore(provideData);
    const data = reactive({});

    const route = useRoute();
    watch(() => route, (newVal) => {
      const { matched } = newVal;
      data.flag = matched.some(item => item.name === 'enterprise');
    }, { immediate: true, deep: true });

    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.layout {
  box-sizing: border-box;
  width: 100%;
  // min-width: 1200px;
  // min-width: 1366px;
  background-color: #F8F8F8;
  .cks-layout-breadcrumb {
    background: #ffffff;
    border-bottom: 1px solid #f1f1f1;
  }
}
// ::v-deep .el-main{
//   padding:0;
// }
</style>
