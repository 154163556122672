<template>
  <div style="min-height:20px">{{text}}</div>
</template>

<script>

export default {
  name: 'cksSpan',
  desc: 'span布局',
  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>
