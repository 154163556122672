/**
 * 基础异常类(便于识别是业务抛出的还是其他依赖库抛出来的)
 */
export class CksError extends Error {
  name = 'cksError'

  /**
   * @description 附加异常信息
   * @memberof cksError
   * @type {*}
   */
  detail = null

  /**
   * @param {String} msg 异常信息
   * @param {*} detail 附加异常信息
   */
  constructor(msg, detail) {
    super(msg);
    this.detail = detail;
  }
}

/**
 * api请求异常类
 */
export class cksRequestError extends CksError {
  name = 'cksRequestError'

  /**
   * @description 业务响应码
   * @memberof cksRequestError
   * @type {number|401|403|404|500|5000|5001|504|6000}
   */
  code = 500

  /**
   * @description 业务响应信息
   * @memberof cksRequestError
   * @type {String}
   */
  msg = 'unknown'

  /**
   * @param {String} msg 异常信息
   * @param {number|401|403|404|500|5000|5001|504} code 业务响应码
   * @param {String} resmsg 业务响应信息
   * @param {*} detail 附加异常信息
   */
  constructor(msg, code, resmsg, detail) {
    super(msg, detail);
    this.code = code;
    this.msg = resmsg;
  }
}
