import { showErrorMsg } from '@/utils';
import { contentType } from './mime-types';

async function getErrorMsg(blob) {
  const msg = await blob.text();
  showErrorMsg(JSON.parse(msg)?.msg);
}

function down(res) {
  const link = document.createElement('a');
  // 解析文件名
  const params = {};
  let filename = '';
  if (res.data.type === 'application/json') {
    getErrorMsg(res.data);
    return;
  }
  // try {
  //   params = res.config.params || JSON.parse(res.config.data || '{}');
  // } catch (e) {
  //   showError(e);
  // }
  // eslint-disable-next-line
  filename = params.__filename__ || res.headers['content-disposition'] || 'download';
  filename = filename.replace(/(^[\s\S]+filename\s{0,}\*?\s{0,}=\s{0,}(utf-?8'')?)|(;\s{0,}$)|"/gi, '').trim();
  // 文件流
  let { data } = res;
  if (/\..+$/.test(filename)) {
    data = new Blob([res.data], { type: contentType(filename) });
  }
  link.href = window.URL.createObjectURL(data);
  link.download = decodeURIComponent(filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export default down;
export {
  down,
};
