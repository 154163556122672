import CksPageModule from '@/components/cks-page-module';
import CksPagination from '@/components/cks-pagination';
import CksTable from '@comp/cks-table';
import CksSelect from '@comp/cks-select';
import CksEdit from '@comp/cks-edit';
import CksEditSwitch from '@comp/cks-edit-switch';
import CksLink from '@comp/cks-link';
import CksPageWrap from '@/components/cks-page-wrap';
import CksSearch from '@/components/cks-search';
import CksCondition from '@/components/cks-condition';
import CksCombinedTable from '@/components/cks-combined-table';
import CksTableFilter from '@/components/cks-table-filter';
import CksTableOperate from '@/components/cks-table-operate';
import CksTableColumn from '@/components/cks-table-column';
import CksEditForm from '@/components/cks-edit-form';
import CksDatePicker from '@/components/cks-date-picker';
import CksCombinedRoute from '@/components/cks-combined-route';
import CksSpan from '@/components/cks-span';
import CksTableBar from '@/components/cks-table-bar';
import cksUpload from '@/components/cks-upload';
import cksUploadVideo from '@/components/cks-upload-video';

const componentsArr = [
  { key: 'cks-page-module', value: CksPageModule },
  { key: 'cks-pagination', value: CksPagination },
  { key: 'cks-table', value: CksTable },
  { key: 'cks-page-module', value: CksPageModule },
  { key: 'cks-select', value: CksSelect },
  { key: 'cks-edit', value: CksEdit },
  { key: 'cks-edit-switch', value: CksEditSwitch },
  { key: 'cks-link', value: CksLink },
  { key: 'cks-page-wrap', value: CksPageWrap },
  { key: 'cks-search', value: CksSearch },
  { key: 'cks-condition', value: CksCondition },
  { key: 'cks-combined-table', value: CksCombinedTable },
  { key: 'cks-table-filter', value: CksTableFilter },
  { key: 'cks-table-operate', value: CksTableOperate },
  { key: 'cks-table-column', value: CksTableColumn },
  { key: 'cks-edit-form', value: CksEditForm },
  { key: 'cks-date-picker', value: CksDatePicker },
  { key: 'cks-combined-route', value: CksCombinedRoute },
  { key: 'cks-span', value: CksSpan },
  { key: 'cks-table-bar', value: CksTableBar },
  { key: 'cks-upload', value: cksUpload },
  { key: 'cks-upload-video', value: cksUploadVideo },
];
export default componentsArr;
