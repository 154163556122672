<template>
  <el-form
    :class="['cks-search', isGrow && 'is-grow']"
    label-position="top"
    :model="modelValue"
    ref="formRef"
    :rules="rules"
    v-bind="$attrs"
    @submit.prevent
  >
    <el-row :gutter="10" class="cks-search-conditions">
      <cks-col
        v-for="item in computedOptions"
        :key="item.prop"
        v-bind="colProps"
      >
      <!-- isProp 是否需要取消表单重置条件 true取消 -->
        <el-form-item>
          <component
            :is="item.comp || 'el-input'"
            v-bind="item.compProps"
            v-model="form[item.prop]"
            :disabled="item.disabled"
            @update:modelValue="handleChange"
            :clearable="item.clearable"
            :teleported="true"
            @keyup.enter="doSearch"
            :placeholder="item.symbol ? '请输入数字' : item.comp == 'el-input' ? '请输入查询条件' : item.comp == 'cks-select' ? '请选择查询条件' : ''"
          >
          <template v-if="item.symbol" #append>
            <el-select @update:modelValue="handleChange" v-model="form[`${item.prop}.symbol`]" placeholder="请选择" class="sel">
              <el-option v-for="item in selectSymbol" :label="item.label" :value="item.value" :key="item" />
            </el-select>
          </template>
          </component>
        </el-form-item>
      </cks-col>
    </el-row>
  </el-form>
</template>

<script>
import {
  computed, nextTick, onMounted, reactive, toRefs, watchEffect,
} from 'vue';
import { take } from 'lodash';
import { getRequireRule, notEmpty, useScreen } from '@/utils';

export default {
  name: 'cksSearch',
  desc: '查询控件',
  emits: ['update:modelValue', 'search', 'reset', 'hide'],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    modelValue: {
      type: Object,
      default: () => ({}),
    },
    rows: {
      type: Number,
      default: 2,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    lockReason: String,
    isTableSearch: {
      type: Boolean,
      default: false,
    },
    tableProp: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { isMediumScreen, isLargeScreen } = useScreen();
    const data = reactive({
      colProps: props.isTableSearch ? { span: 24 } : { span: 6, wmd: 4, wlg: 4 },
      form: {},
      isGrow: false,
      isMediumScreen,
      isLargeScreen,
      rules: {},
      formRef: null,
      // selectSymbol: ['=', '>', '<', '>=', '<='],
      selectSymbol: [
        {
          label: '>',
          value: 'GT',
        },
        {
          label: '<',
          value: 'LT',
        },
        {
          label: '=',
          value: 'EQ',
        },
        {
          label: '>=',
          value: 'GE',
        },
        {
          label: '<=',
          value: 'LE',
        },
      ],
    });

    const limit = computed(() => {
      if (isLargeScreen.value) {
        return 8 * props.rows;
      }
      if (isMediumScreen.value) {
        return 6 * props.rows;
      }
      return 4 * props.rows;
    });

    function handleChange() {
      nextTick(() => {
        Object.entries(data.form).forEach(([key, val]) => {
          if (Array.isArray(val)) {
            data.form[key] = val.filter(notEmpty);
          }
        });
        emit('update:modelValue', data.form);
      });
    }

    function handleReset() {
      emit('reset', { ...data.form });
      data.formRef?.resetFields();
      if (props.isTableSearch) {
        delete data.form[props.tableProp];
        emit('update:modelValue', data.form);
      } else {
        handleChange();
      }
    }

    onMounted(() => {
      props.options.forEach(item => {
        if (!data.form[`${item.prop}.symbol`] && item.symbol) {
          // data.form[`${item.prop}.symbol`] = item.symbol;
          data.form[`${item.prop}.symbol`] = data.selectSymbol.find(item2 => item2.label === item.symbol).value;
        }
      });
    });

    watchEffect(() => {
      if (props.isTableSearch) {
        const obj = {};
        Object.keys(data.form).forEach(key => {
          if (key.indexOf('.') !== -1) {
            obj[key] = data.form[key];
          }
        });
        data.form = props.modelValue;
        Object.assign(data.form, obj);
      } else {
        Object.assign(data.form, props.modelValue);
      }
    });
    watchEffect(() => {
      data.form = props.options.reduce(
        (form, item) => {
          const modelValue = item.compProps?.modelValue;
          if (notEmpty(modelValue)) {
            form[item.prop] = modelValue;
          }
          return form;
        },
        {},
      );
      handleChange();
    });

    const defaultProps = {
      'el-input': {},
    };
    const computedOptions = computed(() => {
      let tableOption = props.options;
      if (props.tableProp) {
        tableOption = props.options.filter(item => item.prop === props.tableProp);
      }

      const originOptions = take(tableOption, data.isGrow ? tableOption.length : limit.value);
      const options = originOptions.map(option => {
        const assignOption = {
          comp: 'el-input',
          ...option,
          compProps: {
            ...defaultProps[option.comp || 'el-input'],
            ...(option.compProps || {}),
          },
        };
        if (assignOption.required) {
          data.rules[assignOption.prop] = [getRequireRule(`${assignOption.label}`)];
        }
        return assignOption;
      });
      return options;
    });
    const hasMore = computed(() => limit.value < props.options.length);
    const doSearch = async () => {
      emit('search');
    };

    return {
      doSearch,
      handleReset,
      handleChange,
      computedOptions,
      hasMore,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__inner:focus {
  border-color: #2F51FF;
}
.cks-search {
  // .cks-search-conditions {
  //   max-height: 146px;
  //   overflow: hidden;
  // }
  .cks-search-btns {
    text-align: center;
  }
  // &.is-grow {
  //   .cks-search-conditions {
  //     max-height: auto;
  //     overflow: auto;
  //   }
  // }
  :deep {
    .el-form-item {
      margin-bottom: 16px;
    }
    .el-form-item__label,
    .el-form--label-top .el-form-item__label {
      padding-bottom: 0;
      line-height: 24px;
    }
  }
  :deep .el-form-item__content {
    line-height: 40px !important;
    display: flex;
  }
}
.form {
  padding-top: 1px;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  z-index: 9999;
}
::v-deep .el-select-dropdown {
  left: 0 !important;
}
::v-deep .el-form-item {
  margin-bottom: 2px !important;
}
.sel {
  width: 66px;
  color: #000;;
}
</style>
