<template>
  <div class="cks-page-wrap">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'PageWrap',
  desc: '页面包裹',
};
</script>

<style lang="scss" scoped>
.cks-page-wrap {
  background: #ffffff;
  padding: 10px;
}
</style>
