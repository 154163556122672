import homePageConfiguration from './home-page-configuration';
import newsCenter from './news-center';
import classicCases from './classic-cases';
import coldStorage from './cold-storage';
import upload from './upload';
import joinUs from './join-us';
import enterSpikeDepth from './enter-spike-depth';
import login from './login';
import receptionClassicCases from './reception-classic-cases';
import receptionNewsCenter from './reception-news-center';
import receptionColdStorage from './reception-cold-storage';
import receptionJoinUs from './reception-join-us';
import receptionEnterSpikeDepth from './reception-enter-spike-depth';
import home from './reception-home-page-configuration';
import operate from './operate';

export default {
  homePageConfiguration,
  newsCenter,
  classicCases,
  coldStorage,
  upload,
  joinUs,
  enterSpikeDepth,
  login,
  receptionClassicCases,
  receptionNewsCenter,
  receptionColdStorage,
  receptionJoinUs,
  receptionEnterSpikeDepth,
  home,
  operate,
};
