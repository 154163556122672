import { createRouter, createWebHistory } from 'vue-router';
import NProgress from 'nprogress';
import { routes } from './router';
import 'nprogress/nprogress.css';
import store from '@/store';

NProgress.configure({ showSpinner: false });
const router = createRouter({
  history: createWebHistory(),
  routes: [...routes],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  NProgress.start();
  document.title = '穗深-穗深冷链';
  if (to.path === '/') {
    next({ name: 'index' });
  } else if (to.path === '/index') {
    next({ name: 'index' });
  } else if (to.path === '/index.html') {
    next({ name: 'index' });
  } else if (to.path === '/console') {
    next({ name: 'homePageConfiguration' });
  } else {
    next();
  }
});

router.afterEach((to) => {
  NProgress.done();
  store.commit('setRoute', to.fullPath);
});

window.router = router;

export default router;
