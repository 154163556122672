<template>
  <div class="cks-combined-table">
    <cks-table-bar class="cks-pl--2n cks-pr--2n">
      <el-tooltip :disabled="!lockExcel" :content="lockExcel" placement="top">
        <cks-link :disabled="disabledExcel" class="cks-mr--2n" v-show="!disableDown" v-loading.fullscreen.lock="fullscreenLoading" @click="excelClick"><el-button :disabled="disabledExcel" size="mini" round>导出Excel</el-button></cks-link>
      </el-tooltip>
      <cks-link class="cks-mr--2n" v-show="needCustomize" @click="setCustomize"><el-button size="mini" round>自定义列</el-button></cks-link>
      <cks-link class="cks-mr--2n" v-show="$attrs.requireRefresh" @click="requireRefresh"><el-button size="mini" round>刷新</el-button></cks-link>
      <cks-link class="cks-mr--2n" v-show="$attrs.isTableSearch" @click="refresh"><el-button size="mini" round>重置</el-button></cks-link>
      <el-tooltip :disabled="searchable" :content="lockReason" placement="top">
        <cks-link class="cks-mr--2n" :disabled="!searchable" v-show="$attrs.isTableSearch" @click="search"><el-button :disabled="!searchable" size="mini" round>查询</el-button></cks-link>
      </el-tooltip>
      <template #right>
        <slot name="btns"></slot>
      </template>
    </cks-table-bar>
    <cks-table @load="handleLoad" v-bind="$attrs" ref="tableRef">
      <slot></slot>
    </cks-table>
  </div>
</template>

<script>
import {
  computed, watch, ref, toRef, reactive, toRefs,
} from 'vue';
import cksTable from '@comp/cks-table';
import cksTableBar from '@comp/cks-table-bar';
import cksLink from '@comp/cks-link';
// import cksColSelector from '@comp/cks-col-selector';
import { useCache } from '@/utils';

export default {
  components: {
    cksTable,
    cksTableBar,
    cksLink,
    // cksColSelector,
  },
  name: 'cksCombinedTable',
  desc: '通用表格',
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    data: Array,
    cols: {
      type: Array,
      default: () => [],
    },
    cacheKey: String,
    disableDown: {
      type: Boolean,
      default: false,
    },
    needCustomize: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: true,
    },
    lockReason: String,
    lockExcel: String,
    disabledExcel: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['load', 'handleCustomize'],
  data() {
    return {
      timerId: null,
    };
  },
  setup(props, { emit }) {
    const data = reactive({
      fullscreenLoading: false,
    });
    const computedCache = computed(() => useCache(props.cacheKey));
    const computedCols = ref([]);
    watch([computedCache, toRef(props, 'cols')], () => {
    }, { immediate: true, deep: true });
    const excelClick = () => {
      data.fullscreenLoading = true;
      emit('export', () => {
        data.fullscreenLoading = false;
      });
    };
    return {
      computedCols,
      setCacheCols: computedCache.value.setVal,
      excelClick,
      ...toRefs(data),
    };
  },
  methods: {
    renderHeader(col, slotData) {
      const slotMethod = this.$slots[`${col.prop}Header`];
      if (typeof slotMethod === 'function') {
        return slotMethod(slotData);
      }
      return col.label;
    },
    handleLoad(...args) {
      this.$emit('load', ...args);
    },
    requireRefresh() {
      this.$refs.tableRef?.refresh();
    },
    refresh() {
      this.$emit('refreshConditions');
      if (this.searchable) {
        this.$refs.tableRef?.refresh();
      }
    },
    search() {
      if (this.timerId) {
        clearTimeout(this.timerId);
        this.timerId = null;
      }
      this.timerId = setTimeout(() => {
        this.$refs.tableRef?.search();
      }, 500);
    },
    clearSelection() {
      this.$refs.tableRef?.clearSelection();
    },
    toggleRowSelection(row, flag = true) {
      this.$refs.tableRef?.toggleRowSelection(row, flag);
    },
    setCustomize() {
      this.$emit('handleCustomize');
    },
    toggleSelection(key, code) {
      this.$refs.tableRef.toggleSelection(key, code);
    },
  },
};
</script>

<style lang="scss" scoped>
.cks-table-empty {
  position: absolute;
  min-height: 50px;
  line-height: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 10px 20px;
  box-sizing: border-box;
  &.is-error {
    color: #ff0000;
  }
}
</style>
