<template>
  <el-link :href="href" @click="handleClick" v-bind="$attrs"><slot></slot></el-link>
</template>

<script>
export default {
  name: 'cksLink',
  desc: '文字链接',
  emits: ['click'],
  props: {
    href: {
      type: String,
      default: '__prevent__',
    },
  },
  methods: {
    handleClick(e) {
      if (this.href === '__prevent__') {
        e.preventDefault();
      }
      this.$emit('click', e);
    },
  },
};
</script>
