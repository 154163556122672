<template>
  <div v-cloak>
    <el-config-provider :locale="local">
      <router-view />
    </el-config-provider>
  </div>
</template>

<script>
import zhCn from 'element-plus/lib/locale/lang/zh-cn';

export default {
  data() {
    // conso
    return { local: zhCn };
  },
};
</script>

<style lang="scss">
  @import url('~@/assets/css/base.css');
  ::v-deep .el-message__icon {
    font-size: 24px !important;
  }
  ::-webkit-scrollbar {
    display: none;
  }

  .el-button.el-button--primary{
    color: #fff !important;
    background-color: #2F51FF !important;
    border-color: #2F51FF !important;
  }

  .el-link.el-link--primary{
    color: #2F51FF !important;
  }

  .el-link.el-link--default:hover{
    color: #2F51FF !important;
  }

  .el-tabs__item.is-active{
    color: #2F51FF !important;
  }
   .el-tabs__active-bar{
    background-color: #2F51FF !important;
  }
  .el-link.is-underline:hover:after {
    border-bottom: 1px solid #2F51FF !important;
  }
  .el-overlay-dialog {
    width: 100%;
    height: 100%;
    .el-dialog {
      top: 40%;
      transform: translateY(-40%);
    }
  }
</style>
