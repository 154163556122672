<template>
  <el-header
    class="wms-layout-page-header wms-size--fh wms-flex wms-flex--vc"
    height="auto"
  >
    <el-button v-show="hasBack" type="text" class="wms-icon-btn wms-mr--2n" @click="handleBack">
      <el-icon :size="24" class="el-icon-back"></el-icon>
    </el-button>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <template v-for="item in matchedRoutes" :key="item.path">
        <el-breadcrumb-item v-if="item.path !== '/'">{{ item.meta.title }}</el-breadcrumb-item>
      </template>
    </el-breadcrumb>
  </el-header>
</template>

<script>
import {
  watch, reactive, toRefs, computed,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const router = useRouter();
    const data = reactive({
      matchedRoutes: [],
    });
    const hasBack = computed(() => (router.currentRoute.value.meta.back !== undefined));
    watch(() => route, (newVal) => {
      data.matchedRoutes = newVal.matched;
    }, { deep: true, immediate: true });

    function handleBack() {
      const name = router.currentRoute.value.meta.back;
      if (name) {
        router.push({
          name,
          params: {},
        });
      }
    }

    return {
      ...toRefs(data),
      hasBack,
      handleBack,
    };
  },
};
</script>

<style scoped>
.wms-layout-page-header {
  height: 56px;
}
.wms-flex {
  display: flex;
  align-items: center;
}
.wms-icon-btn {
  margin-right: 20px;
  color: #2F51FF;
}
.wms-icon-btn:hover {
  color: #2F51FF;
}
</style>
