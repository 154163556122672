<template>
  <el-table
    v-loading="loading"
    :data="rows"
    tooltip-effect="dark"
    ref="tableRef"
    v-bind="$attrs"
    :class="setClassName"
    :id="idNow"
    @sort-change="colSortChange"
  >
    <template #empty v-if="!rows.length || err">
      <div
        :class="['cks-table-empty', err && 'is-error']"
      >{{ err || '暂无数据' }}</div>
    </template>
    <el-table-column v-if="$attrs.sortable" type="selection" fixed="left"></el-table-column>
    <slot></slot>
  </el-table>
  <div class="tbale-pagination" :class="{'tbale-pagination-bgc': isBgcBtm}">
    <el-pagination
      class="cks-pl--2n cks-pr--2n my-pagination"
      @size-change="handelSortChange"
      @current-change="handelSortChange"
      v-model:current-page="pagination.current"
      v-model:page-size="pagination.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      v-if="isPagination"
    ></el-pagination>
  </div>
</template>

<script>
// 暂时使用，后续需要更新
import {
  reactive, toRefs, onMounted, nextTick, computed,
} from 'vue';
// import { useStore } from 'vuex';

export default {
  name: 'cksTable',
  desc: '初版通用表格',
  props: {
    data: Array,
    isBgcBtm: {
      type: Boolean,
      default: true,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    isTableSearch: { // 是否默认显示表格查询条件
      type: Boolean,
      default: false,
    },
    isPagination: {
      type: Boolean,
      default: true,
    },
    dubTitle: {
      type: String,
      default: '',
    },
  },
  emits: ['load'],
  data() {
    return {
    };
  },
  setup(props, { emit }) {
    // const store = useStore();
    const data = reactive({
      // list: [],
      // tableRef: null,
      loading: false,
      // rows: props.data || [],
      rows: props.data || props.isTableSearch ? [{}] : [],
      idNow: String(Date.now()),
      total: 0,
      pagination: {
        current: 1,
        size: 10,
      },
      err: '',
      orderStr: '',
    });
    const setClassName = computed(() => {
      if (!props.isTableSearch) {
        return '';
      }
      return 'my-table-disable';
    });
    const removeElement = () => {
      const table = document.getElementById(data.idNow);
      const t = table.getElementsByClassName('el-table__body-wrapper')[0];
      const child = t?.getElementsByClassName('d-new')[0];
      if (child) {
        t.removeChild(child);
      }
      return t;
    };
    const addElement = () => {
      const t = removeElement();
      const d = document.createElement('div');
      d.innerHTML = data.err || '暂无数据';
      d.setAttribute('class', 'd-new');
      d.setAttribute('style', 'text-align: center;padding: 16px 0px;');
      t.appendChild(d);
    };
    const setTableStyle = () => {
      // 对需要表格查询条件的表格隐藏第一个多选的按钮
      const myTableStyle = document.querySelector('#myTableStyle');
      if (myTableStyle) return;
      const style = document.createElement('style');
      style.setAttribute('id', 'myTableStyle');
      style.innerText = '.my-table-disable tbody>tr:nth-child(1)>td:nth-child(1).el-table-column--selection>div { opacity: 0; }';
      document.body.appendChild(style);
    };
    // 禁止分页输入框输入数字，回车，删除之外的按键
    const disableNotNum = () => {
      const el = document.querySelector('.my-pagination .is-in-pagination.el-input input');
      el?.addEventListener('keydown', (e) => {
        if ((e.keyCode < 48 || e.keyCode > 57) && e.keyCode !== 13 && e.keyCode !== 8) {
          e.preventDefault();
        }
      });
    };
    // 初始化表格不请求接口时的提示信息
    const setTableTitle = () => {
      const table = document.getElementById(data.idNow);
      const t = table.getElementsByClassName('el-table__body-wrapper')[0];
      const d = document.createElement('div');
      d.innerHTML = props.dubTitle;
      d.setAttribute('class', 'd-new');
      d.setAttribute('style', 'text-align: center;padding: 16px 0px;');
      t.appendChild(d);
    };
    onMounted(() => {
      setTableStyle();
      disableNotNum();
      if (props.dubTitle) {
        setTableTitle();
      }
    });
    async function load() {
      data.loading = true;
      data.err = '';
      // data.rows = [];
      data.rows = props.isTableSearch ? [{}] : [];
      emit(
        'load',
        { pagination: data.pagination, orderStr: data.orderStr },
        (res) => {
          try {
            data.loading = false;
            if (res instanceof Error) {
              data.err = res.toString().replace(/Error: /, '');
            } else {
              // if (!Array.isArray(res.records)) throw new Error('records must be Array');
              // if (!Number.isSafeInteger(res.total)) throw new Error('total must be safe integer');
              // data.rows = res.records;
              // data.rows.push(...res.records);
              data.total = res.total;
              if (!props.isTableSearch) {
                data.rows = res.records;
              } else {
                const arr = [data.rows[0]];
                data.rows = arr.concat(res.records);
                // data.rows.push(...res.records);
                if (props.isTableSearch && data.rows.length === 1) {
                  nextTick(() => {
                    addElement();
                  });
                } else {
                  removeElement();
                }
              }
            }
          } catch (e) {
            data.err = e.toString().replace(/Error: /, '');
          }
        },
      );
    }
    function handelSortChange() {
      data.pagination.current = Math.floor(data.pagination.current);
      load();
    }
    // #region
    // async function load() {
    //   return new Promise((resolve) => {
    //     data.loading = true;
    //     data.err = '';
    //     data.rows = [];
    //     emit(
    //       'load',
    //       { pagination: data.pagination },
    //       (res) => {
    //         try {
    //           data.loading = false;
    //           if (res instanceof Error) {
    //             data.err = res.toString().replace(/Error: /, '');
    //           } else {
    //             // if (!Array.isArray(res.records)) throw new Error('records must be Array');
    //             // if (!Number.isSafeInteger(res.total)) throw new Error('total must be safe integer');
    //             data.rows = res.records;
    //             data.total = res.total;
    //             resolve();
    //           }
    //         } catch (e) {
    //           data.err = e.toString().replace(/Error: /, '');
    //         }
    //       },
    //     );
    //   });
    // }
    // function handelSortChange() {
    //   load().then(() => {
    //     const ids = store.state.selectTableList.map(item => item.id);
    //     data.rows.forEach((item) => {
    //       if (ids.includes(item.id)) {
    //         data.tableRef.toggleRowSelection(item, true);
    //       }
    //     });
    //   });
    // }
    // const handleSelectionChange = (row) => {
    //   const ids = store.state.selectTableList.map(item => item.id);
    //   const arr = row.filter(item => !ids.includes(item.id));
    //   store.commit('pushSelectTableList', arr); // 排他后push数据,防止重复加入
    //   const ids3 = row.map(item => item.id);
    //   const ids4 = data.rows.filter(item => !ids3.includes(item.id)).map(item => item.id);
    //   store.commit('updateSelectTableList', store.state.selectTableList.filter(item => !ids4.includes(item.id)));
    // };
    // onMounted(() => {
    //   store.commit('updateSelectTableList', []);
    // });
    // #endregion
    if (!props.data && !props.lazy) {
      onMounted(() => load());
    }

    const colSortChange = ({ prop, order }) => {
      if (order === 'ascending') {
        data.orderStr = `${prop}[ASC]`;
      } else if (order === 'descending') {
        data.orderStr = `${prop}[DESC]`;
      } else {
        data.orderStr = null;
      }
      load();
    };
    return {
      ...toRefs(data),
      handelSortChange,
      load,
      setClassName,
      setTableStyle,
      colSortChange,
    };
  },
  methods: {
    search() {
      this.pagination.current = 1;
      this.load();
    },
    refresh() {
      this.load();
    },
    clearSelection() {
      this.$refs.tableRef?.clearSelection();
    },
    toggleRowSelection(row, flag = true) {
      this.$refs.tableRef?.toggleRowSelection(row, flag);
    },
    toggleSelection(key, code) {
      const flagCode = code?.split(',') ?? [];
      this.rows.forEach(item => {
        if (flagCode.includes(item[key])) {
          this.$refs.tableRef.toggleRowSelection(item, true);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cks-table-empty {
  position: absolute;
  min-height: 50px;
  line-height: 24px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  padding: 10px 20px;
  box-sizing: border-box;
  &.is-error {
    color: #ff0000;
  }
}

.tbale-pagination {
  width: 100%;
  height: 26px;
  margin: 10px 0px;
  .el-pagination {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    background-color:#fff !important;
  }
  .btn-prev, .btn-next {
    background-color: #fff !important;
  }
  :deep .el-pager li {
    background-color: #fff !important;
    color: #606266;
    min-width: 22px;
    margin: 0px 10px;
    height: 22px;
    line-height: 22px;
  }
  :deep .el-pager li.active {
    min-width: 22px;
    min-height: 22px;
    background: #2F51FF !important;
    border-radius: 2px;
    color: #fff;
  }
}
:deep .tbale-pagination-bgc {
  .el-pagination {
    background-color:#fff !important;
  }
  .btn-prev, .btn-next {
    background-color: #fff !important;
  }
  .el-pager li {
    background-color: #fff !important;
  }
}

</style>
