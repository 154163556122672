import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { isPlainObject } from 'lodash';
import routerIns from '@/router';
import { layoutMap } from '@/router/router';
import {
  assert, loopTree, setSessionId, showErrorMsg,
} from '@/utils';

function quickSet(key, defaultVal) {
  return function set(state, payload) {
    state[key] = payload ?? defaultVal;
  };
}

const State = {
  hidden: false, // 隐藏路由
  createCount: 1, // 记录是否第一次进入
  // 登录用户信息
  loginUser: {},
  // 转换后的授权码
  permCodes: {},
  // 转换后的可用路由
  permRouteNames: {},
  selectedRow: {},
  roleManage: {},
  dialogVisible: false,
  userInfo: {
    userSettings: [],
    roles: [],
    userType: 1,
  },
  roleInfo: { // 树
    rights: [],
    storages: [],
    users: [],
  },
  users: null,
  routers: [],
  routeCh: [],
  reqHeader: '',
  systemCode: [],
  subCode: [],
  levelInfo: '',
  currentInfo: {},
  // 字典数据
  dicts: {},
  purchaseForm: [], // 采购申请单管理
  formHeader: [],
  editNum: [],
  po: [], // po管理
  poCreate: [],
  detailCreate: [],
  asnEdit: [], // ASN管理
  routerList: [], // 保存tabs页签路由信息
  editableTabsValue: '', // 当前选中的tabs页签名称
  selectTableList: [], // 选中的表格行数据,用于多选后删除使用

};
const getters = {
  getUserName(state) {
    return state.loginUser.userInfo?.realName ?? '';
  },
  getRoute(state) {
    return state.routeCh;
  },
  getDict: (state) => (name) => state.dicts[name],
};
const mutations = {
  updateHidden: (state, val = false) => {
    state.hidden = val;
  },
  updateCreateCount: (state) => {
    state.createCount++;
  },
  reductionCount: (state) => {
    state.createCount = 1;
  },
  updateSelectTableList: (state, val) => {
    state.selectTableList = val;
  },
  pushSelectTableList: (state, val) => {
    state.selectTableList.push(...val);
  },
  // 更新router数组的方法
  updateRouterList: (state, val) => {
    // 判断是否存在该路由?? => 1.存在相同的路由信息不添加，直接修改tab页签的选中;  2.不存在push该路由保存路由信息，并修改tab页签的选中;
    const index = state.routerList.findIndex(item => item.name === val.name);
    // 不存在路由
    if (index === -1) {
      state.routerList.push(val);
      state.editableTabsValue = val.name;
    } else {
      state.editableTabsValue = state.routerList[index].name;
    }
  },

  // 设置children的方法
  setChildren: (state, val) => {
    const index = state.routerList.findIndex(item => item.name === val.name);
    if (index !== -1) {
      if (state.routerList[index].children.length === 0 || val.children.length === 0) {
        state.routerList[index].children = val.children;
      } else {
        const arr = state.routerList[index].children.filter(item => item.name !== val.children[0].name);
        arr.push(val.children[0]);
        state.routerList[index].children = arr;
      }
    }
  },

  // 更新选中tab页签的方法
  updateEditableTabsValue: (state, val) => {
    state.editableTabsValue = val;
  },

  // 删除tab页签的方法
  removeTabsValue: (state, val) => {
    if (state.routerList.length === 1) {
      return showErrorMsg('最少保留一个页签');
    }
    state.routerList = state.routerList.filter(item => item.name !== val);
  },

  // 还原记录路由信息的tabs页签，1. 切换企业与仓库调用; 2. 登录页面mounted钩子调用; 3.退出登录调用; 4. 其他地方...
  setTabsValue: (state) => {
    state.routerList = [];
    state.editableTabsValue = '';
  },

  // 关闭其他tab页签; 1.保留当前选中的tab页签，其他的都删除
  closeTabs: (state) => {
    state.routerList = state.routerList.filter(item => item.name === state.editableTabsValue);
  },
  setPermCodes: quickSet('permCodes', {}),
  setPermRouteNames: quickSet('permRouteNames', {}),
  setPerferPage: quickSet('perferPage', {}),
  clearUser(state) {
    state.users = null;
    state.routers = [];
  },
  setUser: quickSet('loginUser'),
  setRoutes: quickSet('routers'),
  setDict(state, { name, value }) {
    state.dicts[name] = value;
  },

  updateRole(state, value) {
    if (!Object.keys(value).length) {
      Object.assign(state.roleInfo, {
        rights: [],
        storages: [],
        users: [],
      });
    } else {
      Object.assign(state.roleInfo, value);
    }
  },
  dialogSwitch(state, value) {
    state.dialogVisible = value;
  },
  setPosition(state, value) {
    state.position = value;
  },
  setSign(state, value) {
    state.signInOrLogIn = value;
  },
  setUserInfo(state, value) {
    if (!Object.keys(value).length) {
      state.userInfo = {
        userSettings: [],
        roles: [],
        userType: 1,
      };
    } else {
      Object.assign(state.userInfo, value);
    }
  },
  setRoute(state, value) {
    const { matched = [] } = routerIns.resolve(value);
    state.routeCh = matched.map(item => item.meta?.title).filter(Boolean);
  },
  // 获取systemCode的数据
  setSystemCode: quickSet('systemCode'),
  clearSystemCode(state) {
    state.systemCode = [];
  },
  // 获取create组件的数据
  setSubCode: quickSet('subCode'),
  clearSubCode(state) {
    state.subCode = [];
  },
  setLevelInfo(state, value) {
    state.levelInfo = value;
  },
  setCurrentInfo(state, value) {
    state.currentInfo = value;
  },
  setSelectedRow(state, value) {
    state.selectedRow = value;
  },
  setPackageSpecConfig(state, value = []) {
    const result = {};
    value.forEach(item => {
      result[item.label] = {
        list: item.options.map(item1 => ({ ...item1, value: item1.label, label: item1.name })),
        map: item.options.reduce((res, val) => ({ ...res, [val.label]: val.name }), {}),
      };
    });
    state.packageSpecConfig = result;
  },
  // 获取getPage的数据
  setGetPage(state, value) {
    state.getPage = value;
  },
  // 获取purchaseForm的数据
  setPurchaseForm: quickSet('purchaseForm'),
  clearPurchaseForm(state) {
    state.purchaseForm = [];
  },
  // 获取formHeader组件的数据
  setFormHeader: quickSet('formHeader'),
  clearFormHeader(state) {
    state.formHeader = [];
  },
  // 获取editNum组件的数据
  setEditNum: quickSet('editNum'),
  clearEditNum(state) {
    state.editNum = [];
  },
  // 获取po组件的数据
  setPo: quickSet('po'),
  clearPo(state) {
    state.po = [];
  },
  // 获取po新建组件的数据poCreate
  setPoCreate: quickSet('poCreate'),
  clearPoCreate(state) {
    state.poCreate = [];
  },
  // 获取po编辑组件的表单上数据
  setPoEditTop: quickSet('poEditTop'),
  clearPoEditTop(state) {
    state.poEditTop = [];
  },
  // 获取po编辑组件的表单下数据poEdit
  setPoEdit: quickSet('poEdit'),
  clearPoEdit(state) {
    state.poEdit = [];
  },
  // 获取po明细新建组件的数据detailCreate
  setDetailCreate: quickSet('detailCreate'),
  clearDetailCreate(state) {
    state.detailCreate = [];
  },
  // 获取po明细编辑组件的数据
  setDetailEdit: quickSet('detailEdit'),
  clearDetailEdit(state) {
    state.detailEdit = [];
  },
  // 获取asnEdit组件上的数据
  setAsnEdit: quickSet('asnEdit'),
  clearAsnEdit(state) {
    state.asnEdit = [];
  },
};
const actions = {
  clearUser({ commit }) {
    commit('clearUser');
  },
  setUser({ commit }, payload) {
    const {
      permissions = {}, token,
    } = payload;
    // 将不规则的授权信息转成授权码数组
    const permCodes = {};
    if (isPlainObject(permissions)) {
      Object.entries(permissions).forEach(([rootKey, permObj]) => {
        permCodes[rootKey] = true;
        if (isPlainObject(permObj)) {
          Object.entries(permObj).forEach(([moduleKey, codes]) => {
            permCodes[`${rootKey}#${moduleKey}`] = true;
            if (Array.isArray(codes)) {
              codes.forEach((code) => {
                permCodes[`${rootKey}#${moduleKey}#${code}`] = true;
              });
            }
          });
        }
      });
    }
    // todo 用户偏好页面
    const perferPage = {
      firstEntPath: null,
      firstStoragePath: null,
    };
    // 基于授权码过滤可访问路由
    const permRouterName = {};
    loopTree(layoutMap, 'children', (route) => {
      const { name } = route;
      assert(name, 'route property name is required', route);
      assert(!permRouterName[name], `route ${name} has been register`, route);
      const { perm } = route.meta || {};
      if (!perm || permCodes[perm]) {
        permRouterName[name] = true;
        if (!perferPage.firstEntPath && !route.meta?.onlyStorage) {
          perferPage.firstEntPath = name;
        }
        if (!perferPage.firstStoragePath && !route.meta?.onlyCompany) {
          perferPage.firstStoragePath = name;
        }
        return true;
      }
    }, true);
    // 刷新页面重新获取的信息缺少token返回
    if (token) {
      setSessionId(token);
    }
    commit('setUser', payload);
    commit('setRoutes', layoutMap);
    commit('setPermCodes', permCodes);
    commit('setPermRouteNames', permRouterName);
    commit('setPerferPage', perferPage);
  },
};
const myPersistedState = createPersistedState({
  key: 'store',
  storage: window.sessionStorage,
  reducer(state) {
    return { ...state };
  },
});
export default createStore({
  state: State,
  mutations,
  actions,
  getters,
  plugins: [myPersistedState],
});
