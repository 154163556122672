<template>
  <div>
    <cks-page-module>
      <div class="layout-box">
            <div class="layout-xbox" v-if="!showLogin">
                <div class="layout-left">
                    <tab-aside></tab-aside>
                </div>
                <div class="layout-right">
                    <tab-header></tab-header>
                    <router-view></router-view>
                </div>
            </div>
      </div>
      <el-dialog v-model="showLogin" width="30%" title="登录"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false">
         <el-form :model="form" size="large" :rules="rules" ref="formRef" label-position="top">
         <el-form-item label="用户名" prop="userName">
          <el-input
            autofocus
            v-model="form.userName"
            @keyup.enter="confirm"
          ></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            type="password"
            autofocus
            v-model="form.password"
            @keyup.enter="confirm"
          ></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <el-button type="primary" @click="confirm" :loading="updating">确 定</el-button>
      </template>
    </el-dialog>
    </cks-page-module>
  </div>
</template>

<script>
import tabAside from '@/views/components/tab-aside.vue';
import { reactive, toRefs } from 'vue';
import TabHeader from './tab-header.vue';
import {
  setSessionId, getSessionId, showSuccess,
} from '@/utils';
import apis from '@/request/apis';

export default {
  components: {
    tabAside,
    TabHeader,
  },
  setup() {
    const data = reactive({
      showLogin: false,
      form: {
        userName: '',
        password: '',
      },
      formRef: null,
      rules: {
        userName: [
          { required: 'true', message: '请输入用户名' },
        ],
        password: [
          { required: 'true', message: '请输入密码' },
          { pattern: /^[a-zA-Z0-9]+$/, message: '密码只允许输入字母、数字' },
        ],
      },
      updating: false,
    });

    const token = getSessionId();
    if (token) {
      setSessionId(token);
    } else {
      data.showLogin = true;
    }

    async function confirm() {
      try { await data.formRef?.validate(); } catch (e) { return; }
      try {
        data.updating = true;
        const res = await apis.login.login(data.form);
        setSessionId(res);
        data.showLogin = false;
        showSuccess('登录成功');
        window.location.reload();
      } finally {
        data.updating = false;
      }
    }

    return {
      confirm,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.layout-box {
  // width: 1200px;
  min-width: 1200px;
  padding: 20px;
  margin: 0 auto;
  margin-top: 120px;
  height: calc(100vh - 120px);
  .layout-xbox{
    display: flex;
    .layout-left{
        width: 200px;
        background-color: #fff;
        margin-right: .25rem;
        height: calc(100vh - 140px);

    }
    .layout-right{
        margin-left: 20px;
        width: 100%;
        max-width: 100%;
        height: calc(100vh - 140px);
        overflow: hidden;
        background-color: #fff;
    }
  }
}
</style>
