/**
 * @typedef {Object} QueryOption 查询条件配置项
 * @property {string} prop 查询key
 * @property {string} label 条件名称
 * @property {'eq'|'like'|'between'|'in'|'notin'|'gt'|'lt'|'ge'|'le'} eq 查询方式
 * @property {import('@vue/runtime-core').Component | string} comp 渲染组件
 * @property {Object} compProps 渲染组件props
 */

import { watchEffect, reactive } from 'vue';
import { isDef, merge } from './common';

/**
 * 生成配置生成器
 * @param {function | QueryOption} defaultOptions 默认配置
 * @returns
 */
function buildCreator(defaultOptions) {
  /**
   * @param {string} prop 查询key
   * @param {string} label 条件名称
   * @param {Array<string, string>} defaultVal 默认值
   *
   *@param {QueryOption} options 扩展配置(对象，后面的配置都可以写进去)
   *@param {options} clearable 是否可以清空选项
   *
   * @param {QueryOption} disabled 是否可编辑
   * @param {QueryOption} required 是否必须
   * @param {QueryOption} notVisible 是否可见
   * @returns {QueryOption} 查询配置
   */
  return function getQueryOption(prop, label, defaultVal, options, disabled, required, notVisible) {
    const customOptions = { prop, label };
    if (isDef(defaultVal)) {
      customOptions.compProps = { modelValue: defaultVal };
    }
    defaultOptions.disabled = disabled;
    defaultOptions.required = required;
    defaultOptions.notVisible = notVisible;
    defaultOptions.clearable = options?.clearable || true;
    return merge(
      {},
      typeof defaultOptions === 'function'
        ? defaultOptions(prop, label, defaultVal, options)
        : defaultOptions,
      customOptions,
      options,
    );
  };
}

/**
 * 生成日期范围查询配置
 * @param {string} prop 查询key
 * @param {string} label 条件名称
 * @param {Array<string, string>} defaultVal 默认值
 * @param {QueryOption} options 扩展配置
 * @returns {QueryOption} 查询配置
 */
export const getDateRangeQueryOption = buildCreator({
  op: 'between',
  comp: 'el-date-picker',
  compProps: {
    valueFormat: 'YYYYMMDD',
    type: 'daterange',
    style: 'width: 100%',
  },
});

/**
 * 生成日期查询配置
 */
export const getDateQueryOption = buildCreator({
  op: 'dateeq',
  comp: 'el-date-picker',
  compProps: {
    valueFormat: 'YYYYMMDD',
    type: 'date',
    style: 'width: 100%',
  },
});

/**
 * 生成模糊查询配置
 * @param {string} prop 查询key
 * @param {string} label 条件名称
 * @param {Array<string, string>} defaultVal 默认值
 * @param {QueryOption} options 扩展配置
 * @returns {QueryOption} 查询配置
 */
export const getLikeQueryOption = buildCreator({ op: 'like', comp: 'el-input' });

export const getEqQueryOption = buildCreator({ op: 'eq', comp: 'el-input' });

/**
 * 生成单选查询配置
 * @param {string} prop 查询key
 * @param {string} label 条件名称
 * @param {Array<string, string>} defaultVal 默认值
 * @param {QueryOption} options 扩展配置
 * @returns {QueryOption} 查询配置
 */
export const getSelectQueryOption = buildCreator({
  op: 'eq',
  comp: 'cks-select',
  compProps: {
    modelValue: '',
    clearable: true,
    style: 'width: 100%',
  },
});

/**
 * 生成多选查询配置
 * @param {string} prop 查询key
 * @param {string} label 条件名称
 * @param {Array<string, string>} defaultVal 默认值
 * @param {QueryOption} options 扩展配置
 * @returns {QueryOption} 查询配置
 */
export const getMultiSelectQueryOption = buildCreator({
  op: 'in',
  comp: 'cks-select',
  compProps: {
    modelValue: [],
    clearable: true,
    multiple: true,
    style: 'width: 100%',
  },
});

/**
 * 通过字典生成单选查询配置
 * @param {Ref<import('@/dicts').DictItem} dict 字典配置
 * @param {string} prop 查询key
 * @param {string} label 条件名称
 * @param {Array<string, string>} defaultVal 默认值
 * @param {QueryOption} options 扩展配置
 * @returns {QueryOption} 查询配置
 */
export function getDictSelectQueryOption(dict, prop, label, defaultVal, options) {
  const customOption = merge(
    { compProps: { options: [] } },
    options,
  );
  const result = reactive(getSelectQueryOption(
    prop,
    label,
    defaultVal,
    customOption,
  ));
  watchEffect(() => {
    result.compProps.options = dict.value.options || [];
  });

  return result;
}

export default {};
