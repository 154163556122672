<template>
  <!-- <el-popover placement="bottom" width="200" height="200" trigger="click" v-model:visible="visible">
    <template #reference>
      <span>
        <i class="el-icon-arrow-down icon-plus"></i>
      </span>
    </template> -->
    <cks-condition
        :row-style="setStyle" :isTableSearch="true"
        v-bind="$attrs"
        @hide="hide"
      ></cks-condition>
  <!-- </el-popover> -->
</template>
<script>
import { reactive, toRefs } from 'vue';

export default {
  name: 'cksTableFilter',
  desc: '通用表头筛选',
  setup() {
    const data = reactive({
      visible: false,
    });
    function hide() {
      data.visible = false;
    }
    return {
      hide,
      ...toRefs(data),
    };
  },
};
</script>
<style lang="scss" scoped>
  .icon-plus{
    cursor: pointer;
    color: #2395f1;
  }
</style>
