<template>
  <div class="cks-flex" v-loading="lock">
    <template v-if="edit">
      <el-input v-model="editVal" size="mini"></el-input>
      <el-link
        type="primary"
        class="cks-ml cks-flex--keep"
        @click.prevent="submit"
      >保存</el-link>
      <el-link
        class="cks-ml cks-flex--keep"
        @click.prevent="cancel"
      >取消</el-link>
    </template>
    <template v-else>
      {{ value }}
      <el-tooltip content="编辑" placement="right">
        <el-link
          class="cks-ml"
          icon="el-icon-edit"
          href="javascript:void(0)"
          @click="edit = true"
        ></el-link>
      </el-tooltip>
    </template>
  </div>
  <div class="cks-flex">
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'cksSelect',
  desc: '选择器',
  props: ['value', 'params'],
  emits: ['submit'],
  data() {
    return {
      lock: false,
      edit: false,
    };
  },
  methods: {
    submit() {
      this.lock = true;
      this.$emit(
        'submit',
        {
          value: this.editVal,
          params: this.extraParams,
          done: (e) => {
            this.lock = false;
            if (!(e instanceof Error || e === false)) {
              this.edit = false;
            }
          },
        },
      );
    },
    cancel() {
      this.editVal = this.value;
      this.edit = false;
    },
  },
  setup(props) {
    const editVal = ref(props.value);
    const extraParams = ref(props.params);
    return {
      editVal,
      extraParams,
    };
  },
};
</script>
