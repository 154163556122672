<template>
  <div class="cks-table-bar">
    <div class="cks-table-bar__left">
      <slot></slot>
    </div>
    <div class="cks-table-bar__right">
      <slot name="right"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'cksTableBar',
  desc: '初版通用表格操作栏',
};
</script>

<style lang="scss" scoped>
.cks-table-bar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5px;
  font-size: .2rem;
  > * {
    flex-shrink: 0;
  }
}
</style>
