<template>
 <div class="footer">
  <footer2/>
 </div>
</template>
<script>
import Footer2 from './footer.vue';

export default {
  components: {
    Footer2,
  },
};
</script>

<style scoped lang='scss'>
.footer{
  display: flex;
  width: 100%;
  height: 15.625vw;
  background: var(--baseColor1);
}
@media screen and (max-width: 768px) {
  .footer{
    display: flex;
    width: 100%;
    height: auto;
    background: var(--baseColor1);
    flex-direction: column;
    padding-bottom: 4vw;
  }
}
</style>
