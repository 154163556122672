import { createStandardRequest, createPostQueryRequest } from '../helper';

export default {
  save: createStandardRequest('/back/assetOperation/save', 'post'),
  getDetail: createPostQueryRequest('/back/assetOperation/getDetail', 'get'),
  list: createPostQueryRequest('/back/assetOperation/getPage'),
  getPage: createPostQueryRequest('/front/assetOperation/getPage'),
  getDetail2: createPostQueryRequest('/front/assetOperation/getDetail', 'get'),
  config: createPostQueryRequest('/front/assetOperation/cityConfig', 'get'),
  config2: createPostQueryRequest('/back/assetOperation/cityConfig', 'get'),
  storageCount: createPostQueryRequest('/front/assetOperation/storageCount', 'get'),
  up: createPostQueryRequest('/back/assetOperation/up', 'get'),
  down: createPostQueryRequest('/back/assetOperation/down', 'get'),
  first: createPostQueryRequest('/back/assetOperation/first', 'get'),
  last: createPostQueryRequest('/back/assetOperation/last', 'get'),
};
