import axios from './http';
import filter from './filter';
import { down } from './down';
import { merge } from '@/utils';
import { isPlainObject } from 'lodash';

const templates = {
  post: (config, data) => merge(config, {
    data,
    headers: { 'content-type': 'applicatin/json; charset=utf-8' },
  }),
  base: (config, params) => merge(config, { params }),
};

function qs(obj = {}) {
  if (isPlainObject(obj)) {
    return Object.entries(obj).map(([key, val]) => `${encodeURIComponent(key)}=${encodeURIComponent(val)}`).join('&');
  }
  return obj;
}

/**
 * 生成请求配置
 * @param {string} url 请求地址
 * @param {'get'|'post'|'delete'} method 请求方法(默认get)
 * @param {Object<string, any>} params 请求参数
 * @param {import('axios').AxiosRequestConfig} options axios扩展配置
 * @return {import('axios').AxiosRequestConfig} options axios扩展配置
 */
function getRequestConfig(url, method, params, options) {
  const requestConfig = { url, method };
  const templateFn = templates[method] || templates.base;
  templateFn(requestConfig, params);
  merge(requestConfig, options);
  return requestConfig;
}

/**
 * 创建标准请求
 * @param {string} url 请求地址
 * @param {'get'|'post'|'delete'} method 请求方法(默认get)
 * @return {import('axios').AxiosPromise}
 */
export function createStandardRequest(url, method = 'get') {
  return function request(params, options = {}) {
    const requestConfig = getRequestConfig(url, method, params, options);
    return axios(requestConfig).then(filter);
  };
}

/**
 * 创建查询表单请求(入参非json格式)
 * @param {string} url 请求地址
 * @return {import('axios').AxiosPromise}
 */
export function createPostQueryRequest(url, method = 'post') {
  return function request(params, options = {}) {
    const requestConfig = getRequestConfig(url, method, params, options);
    merge(requestConfig, {
      headers: { 'content-type': 'application/x-www-form-urlencoded; charset=utf-8' },
      data: qs(requestConfig.data),
    });
    return axios(requestConfig).then(filter);
  };
}

/**
 * 创建下载请求
 * @param {string} url 请求地址
 * @param {'get'|'post'|'delete'} method 请求方法(默认get)
 * @return {import('axios').AxiosPromise}
 */
export function createDownloadRequest(url, method = 'post', formData) {
  return function request(params, options = { responseType: 'blob' }) {
    const requestConfig = getRequestConfig(url, method, params, options);
    if (formData) {
      merge(requestConfig, {
        headers: { 'content-type': 'application/x-www-form-urlencoded; charset=utf-8' },
        data: qs(requestConfig.data),
      });
    }
    return axios(requestConfig).then(down);
  };
}

/**
 * 创建上传请求
 * @param {string} url 请求地址
 * @return {import('axios').AxiosPromise}
 */
export function createUploadRequest(url) {
  return function request(params, options = {}) {
    options = merge({ headers: { 'content-type': 'multipart/form-data;charset=UTF-8' } }, options);
    params = Object.entries(params).reduce((res, [key, val]) => {
      res.append(key, val);
      return res;
    }, new FormData());
    const requestConfig = getRequestConfig(url, 'post', params, options);
    return axios(requestConfig).then(filter);
  };
}

async function getImgURL(res) {
  const blob = new Blob([res.data]);
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return reader;
}

/**
 * 创建请求图片并返回图片url请求
 * @param {string} url 请求地址
 * @param {'get'|'post'|'delete'} method 请求方法(默认get)
 * @return {import('axios').AxiosPromise}
 */
export function createImgRequest(url, method = 'get') {
  return function request(params, options = { headers: { 'content-type': 'multipart/form-data;charset=UTF-8' }, responseType: 'blob' }) {
    const requestConfig = getRequestConfig(url, method, params, options);
    return axios(requestConfig).then(getImgURL);
  };
}

/**
 * 是否是确认响应内容
 * @param {import('@/utils/cks-error').cksRequestError} e 异常
 * @returns {boolean}
 */
export function isConfirmResponse(e) {
  return ~~e.code === 6000;
}
