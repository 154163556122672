<template>
  <el-col>
    <el-date-picker
      v-bind="$attrs"
      type="date"
      format='YYYY-MM-DD'
      value-format='YYYY-MM-DD'
      :style="style"
    ></el-date-picker>
  </el-col>
</template>

<script>
export default {
  name: 'cksDataPicker',
  desc: '日期选择器',
  props: {
    options: {
      type: String,
      default: '',
    },
    valueKey: {
      type: String,
      default: 'label',
    },
    labelKey: {
      type: String,
      default: 'name',
    },
    style: {
      type: String,
      default: 'width: 100%',
    },
  },
};
</script>
