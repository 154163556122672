/* eslint-disable */
import db from './mime-db.json';
import { extname } from 'path';

const EXTRACT_TYPE_REGEXP = /^\s*([^;\s]*)(?:;|\s|$)/
const TEXT_TYPE_REGEXP = /^text\//i

const extensions = Object.create(null);
const types = Object.create(null)

function getCharset (type) {
  if (!type || typeof type !== 'string') {
    return false
  }
  const match = EXTRACT_TYPE_REGEXP.exec(type)
  const mime = match && db[match[1].toLowerCase()]

  if (mime && mime.charset) {
    return mime.charset
  }

  if (match && TEXT_TYPE_REGEXP.test(match[1])) {
    return 'UTF-8'
  }

  return false
}

function lookup (path) {
  if (!path || typeof path !== 'string') {
    return false
  }

  const extension = extname('x.' + path)
    .toLowerCase()
    .substr(1)

  if (!extension) {
    return false
  }

  return types[extension] || false
}

function contentType (str) {
  if (!str || typeof str !== 'string') {
    return false
  }

  let mime = str.indexOf('/') === -1
    ? lookup(str)
    : str

  if (!mime) {
    return false
  }

  if (mime.indexOf('charset') === -1) {
    const charset = getCharset(mime)
    if (charset) mime += '; charset=' + charset.toLowerCase()
  }

  return mime
}

const preference = ['nginx', 'apache', undefined, 'iana']
Object.keys(db).forEach((type) => {
  const mime = db[type]
  const exts = mime.extensions

  if (!exts || !exts.length) {
    return
  }

  extensions[type] = exts

  for (let i = 0; i < exts.length; i++) {
    let extension = exts[i]

    if (types[extension]) {
      const from = preference.indexOf(db[types[extension]].source)
      const to = preference.indexOf(mime.source)

      if (types[extension] !== 'application/octet-stream' &&
        (from > to || (from === to && types[extension].substr(0, 12) === 'application/'))) {
        continue
      }
    }

    types[extension] = type
  }
})

export { contentType }
