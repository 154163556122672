export default function filter(response) {
  return response?.data?.data;
  // console.log(response.config)
  // let res = response?.data?.data;
  // if (res === null || res === undefined) {
  //   res = '';
  // }
  // res.__code = response?.data?.code;
  // res.__msg = response?.data?.msg;
  // return res;
}
