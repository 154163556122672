import { isEmpty, mergeWith } from 'lodash';
import { ref, computed } from 'vue';

/**
 * 合并对象(目标值或待合并值中其中某个为数组时直接覆盖)
 * @param {Object} targetObject 目标对象
 * @param {Array} sourceObjects 待合并对象
 */
export function merge(targetObject, ...sourceObjects) {
  return mergeWith(
    targetObject,
    ...sourceObjects,
    (targetVal, sourceVal) => {
      if (Array.isArray(targetVal) || Array.isArray(sourceVal)) {
        return sourceVal;
      }
      return undefined;
    },
  );
}

export function isDef(val) {
  return val !== null && val !== undefined;
}

export function isNotDef(val) {
  return !isDef(val);
}

export function notEmpty(val) {
  if (isNotDef(val)) return false;
  if (typeof val === 'object') return !isEmpty(val);
  if (typeof val === 'boolean') return true;
  if (typeof val === 'number') return !Number.isNaN(val);
  return !!val;
}

/**
 * 创建有状态函数(执行时自动拦截后续执行请求)
 * @param {Function} fn 执行函数
 *
 * @typedef StatefulFunction 有状态函数
 * @property {import('@vue/reactivity').Ref<'pending'|'fulfilled'|'rejected'|'normal'>} status 执行状态
 * @property {import('@vue/reactivity').Ref<Error>} error 异常信息(当且仅当status为rejected时有值)
 * @property {import('@vue/reactivity').Ref<boolean>} pending 是否执行中
 * @property {typeof fn} handler 执行函数
 * @returns {StatefulFunction}
 */
export function createStatefulFunction(fn) {
  const status = ref('normal');
  const error = ref();
  const pending = computed(() => status.value === 'pending');
  function handler(...args) {
    if (!pending.value) {
      status.value = 'pending';
      error.value = null;
      try {
        const res = fn(...args);
        if (res instanceof Promise) {
          res
            .then((v) => {
              status.value = 'fulfilled';
              return v;
            })
            .catch((e) => {
              status.value = 'rejected';
              error.value = e;
              return e;
            });
        } else {
          status.value = 'fulfilled';
        }
        return res;
      } catch (e) {
        status.value = 'rejected';
        error.value = e;
      }
    }
  }
  return {
    status,
    pending,
    error,
    handler,
  };
}
