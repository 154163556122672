<template>
  <div class="cks-size--full">
    <div class="cks-child-route" ref="childRef">
      <router-view/>
    </div>
    <div class="cks-parent-route">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {
  ref,
  onMounted,
} from 'vue';

export default {
  name: 'cksCombinedRoute',
  desc: '组合路由',
  emits: ['active', 'inactive'],
  setup(props, { emit }) {
    const childRef = ref();
    const childCount = null;

    function checkChildRoute() {
      if (Number.isNaN(Number(childCount))) return;
      const count = childRef.value.childElementCount;
      emit(count > 0 ? 'inactive' : 'active');
    }

    function init() {
      if (childRef.value) {
        const observer = new MutationObserver(checkChildRoute);
        observer.observe(childRef.value, { childList: true });
      } else {
        setTimeout(init, 100);
      }
    }

    onMounted(init);

    return {
      childCount,
      childRef,
    };
  },
};
</script>

<style lang="scss" scoped>
.cks-child-route {
  @include fullSize();
  display: none;
  & + .cks-parent-route {
    @include fullSize();
  }
  &:not(:empty) {
    display: block;
    & + .cks-parent-route {
      display: none;
    }
  }
}
</style>
