<template>
  <div class="aside-box">
      <div class="aside-title">{{ title }}</div>
      <el-menu
        background-color="#ffffff"
        text-color="#3d3d3d"
        active-text-color="#2F51FF"
        router
        unique-opened
        :default-active="route.path"
        :collapse="isCollapse"
        @select="select"
      >
      <template v-for="item in moduleRouters" :key="item.name">
        <template v-if="!item['hidden']">
            <el-sub-menu v-if="!item.path && item.children && item.children.length" :index="concatPath(item.path)">
              <template #title>
                <i :class="item.meta.icon"></i>
                <span>{{ item.meta.title }}</span>
              </template>
              <template v-for="sub in item.children" :key="sub.name">
                  <el-menu-item :index="concatPath(item.path, sub.path)" v-if="!sub['hidden']">
                    <i :class="sub.meta['icon']"></i>
                    <template #title>{{ sub.meta.title }}</template>
                  </el-menu-item>
              </template>
            </el-sub-menu>
            <el-menu-item v-else :index="concatPath(item.path)">
              <i :class="item.meta['icon']"></i>
              <template #title>{{ item.meta.title }}</template>
            </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue';
import { layoutMap } from '@/router/router';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const paths = window.location.pathname.split('/').filter(Boolean);
    const data = reactive({
      currentModule: paths[0],
      moduleRouters: [],
      title: '',
    });
    data.moduleRouters = layoutMap.find(item => item.path === data.currentModule)?.children ?? [];
    data.title = layoutMap.find(item => item.path === data.currentModule)?.meta?.title ?? '';
    const concatPath = (p_path, c_path = '') => `/${data.currentModule}${p_path !== '' ? `/${p_path}` : '/'}${c_path !== '' ? `/${c_path}` : ''}`;

    return {
      concatPath,
      route,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
    .aside-box{
        .aside-title{
            border-bottom: 1px solid #f1f1f1;
            font-size: 20px;
            font-weight: 700;
            text-align: center;
            height: 60px;
            line-height: 60px;
        }
    }
.el-menu-item.is-active {
  color: #2F51FF;
}
</style>
