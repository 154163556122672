import { createApp } from 'vue';
import ElementPlus from 'element-plus';
import pick from 'lodash/pick';

import store from './store';
import api from './request/apis/index';
import router from './router';
import App from './app.vue';
import bindApp from './utils';
import './assets/theme/index.css';
import './assets/css/common.scss';
import 'animate.css/animate.min.css';
import componentsArr from '@/components/components';
import 'hover.css';

const app = createApp(App).use(store).use(router);
app.use(ElementPlus);
app.config.globalProperties.$api = api;
app.config.globalProperties.$pick = pick;
app.config.globalProperties.$ELEMENT = { size: 'small' };

componentsArr.forEach(item => {
  app.component([item.key], item.value);
});
bindApp(app);

async function start() {
  app.mount('#app');
}

start();
