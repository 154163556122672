<template>
  <div class="cks-edit-switch cks-flex cks-flex--vc">
    <i :class="['cks-edit-switch__icon', isActive && 'is-active']"></i>
    <span class="cks-ml">{{ text }}</span>
    <el-popconfirm :title="tip || '请确认是否提交操作'" @confirm="submit">
      <template #reference>
        <el-button :loading="lock" class="cks-ml--2n" type="text" :disabled="disabled">
          {{ btnText }}
        </el-button>
      </template>
    </el-popconfirm>
  </div>
</template>

<script>
import {
  computed,
  reactive, toRef, toRefs,
} from 'vue';

export default {
  name: 'cksEditSwitch',
  desc: '编辑开关',
  emits: ['submit'],
  props: {
    tip: {},
    value: {},
    params: {},
    activeValue: {
      default: 1,
    },
    inactiveValue: {
      default: 0,
    },
    activeText: {
      type: String,
      default: '已激活',
    },
    inactiveText: {
      type: String,
      default: '已关闭',
    },
    activeBtnText: {
      type: String,
      default: '关闭',
    },
    inactiveBtnText: {
      type: String,
      default: '激活',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      editVal: toRef(props, 'value'),
      lock: false,
      extraParams: toRef(props, 'params'),
    });

    async function submit() {
      data.lock = true;
      emit(
        'submit',
        {
          value: data.editVal === props.activeValue ? props.inactiveValue : props.activeValue,
          params: data.extraParams,
          done: () => {
            data.lock = false;
          },
        },
      );
    }

    const isActive = computed(() => (props.value === props.activeValue));
    const text = computed(() => (isActive.value ? props.activeText : props.inactiveText));
    const btnText = computed(() => (isActive.value ? props.activeBtnText : props.inactiveBtnText));

    return {
      submit,
      isActive,
      text,
      btnText,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.cks-edit-switch__icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  background: $--disabled-color-base;
  border-radius: 100%;
  &.is-active {
    background: $--color-primary;
  }
}
</style>
