<template>
   <el-select
    v-model="getValue"
    :clearable="clearable"
    v-bind="$attrs"
    filterable
    remote
    :remoteMethod="computedFilterMethod"
    @visible-change="visibleChange"
    >
    <el-option
      v-for="item in computedOptions"
      :key="item.value"
      :label="item.label"
      :value="item.value"
    />
  </el-select>
</template>

<script>
import { computed, ref } from 'vue';

export default {
  name: 'cksSelect',
  desc: '选择器',
  props: {
    modelValue: {
      type: [Number, String, Boolean],
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
    valueKey: {
      type: String,
      default: 'label',
    },
    labelKey: {
      type: String,
      default: 'name',
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    filterMethod: Function,
  },
  setup(props, { emit }) {
    const query = ref('');
    const computedOptions = computed(
      () => props.options
        .map(item => ({
          value: `${item[props.valueKey]}`,
          label: item[props.labelKey],
          disabled: item.disabled,
        }))
        .filter(item => (
          !query.value
          || (item.value.includes(query.value) || item.label.includes(query.value))
        )),
    );
    const computedFilterMethod = computed(
      () => {
        if (typeof props.filterMethod === 'function') {
          return props.filterMethod;
        }
        return qs => {
          query.value = qs;
        };
      },
    );
    const getValue = computed({
      get() {
        return props.modelValue;
      },
      set(value) {
        emit('update:modelValue', value);
      },
    });

    function visibleChange(val) {
      if (!val) {
        query.value = '';
      }
    }

    return {
      visibleChange,
      computedOptions,
      computedFilterMethod,
      query,
      getValue,
    };
  },
};
</script>

<style scoped>
::v-deep.el-select .el-input.is-focus .el-input__inner {
  border-color: #2F51FF;
}
::v-deep.el-select .el-input__inner:focus {
  border-color: #2F51FF;
}
.el-select-dropdown__item.selected {
  color: #2F51FF;
}
</style>
