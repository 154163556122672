<template>
  <el-form
    class="cks-edit-form"
    :disabled="lock"
    ref="formRef"
    v-bind="$attrs"
  >
    <div class="cks-edit-form__header">
      <h3>{{ title }}</h3>
      <div v-if="!disabled" class="cks-edit-form__btns">
        <cks-link type="primary" v-show="lock" @click="handleEdit">编辑</cks-link>
        <template v-if="!lock">
          <el-button class="save" type="text" :loading="loading" @click="handleSave">保存</el-button>
          <!-- <cks-link type="primary" :loading="loading" @click="handleSave">保存</cks-link> -->
          <cks-link v-show="cancelable" class="cks-ml--2n" @click="handleCancel">取消</cks-link>
        </template>
      </div>
    </div>
    <div class="cks-edit-form__content">
      <slot></slot>
    </div>
  </el-form>
</template>

<script>
import {
  provide, reactive, ref, toRef, toRefs, watch,
} from 'vue';

export default {
  name: 'cksEditForm',
  desc: '编辑表单',
  emits: ['save', 'cancel', 'edit'],
  props: {
    title: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    cancelable: {
      type: Boolean,
      default: true,
    },
    isPosi: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    unlock() {
      this.lock = false;
    },
    locked() {
      this.lock = true;
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      lock: true,
      loading: false,
    });
    const formRef = ref();
    watch(() => data.lock, (newVal) => {
      if (props.isPosi) {
        if (newVal) {
          emit('updatePosi', true);
        } else {
          emit('updatePosi', false);
        }
      }
    }, { immediate: true });
    provide('lock', toRef(data, 'lock'));
    async function handleSave() {
      try { await formRef.value.validate(); } catch (e) { return; }
      formRef.value.clearValidate();
      data.loading = true;
      emit('save', (e) => {
        data.loading = false;
        if (!(e instanceof Error) && e !== false) {
          data.lock = true;
        }
      });
    }

    function handleEdit() {
      data.lock = false;
      emit('edit');
    }

    function handleCancel() {
      data.lock = true;
      formRef.value.clearValidate();
      emit('cancel');
    }
    const proxyMethods = ['validate', 'validateField', 'resetFields', 'clearValidate'];
    return {
      handleEdit,
      handleSave,
      handleCancel,
      formRef,
      ...toRefs(data),
      ...proxyMethods.reduce((obj, name) => ({
        ...obj,
        [name](...args) {
          return formRef.value[name].apply(...args);
        },
      }), {}),
    };
  },
};
</script>

<style lang="scss" scoped>
.cks-edit-form {
  display: flex;
  width: 100%;
  flex-direction: column;
  .cks-edit-form__header {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 48px;
    h3 {
      font-size: 16px;
    }
    .cks-edit-form__btns{
      display: flex;
      justify-items: center;
      .save{
        color: #2F51FF !important;
      }
    }
  }
  .cks-edit-form__content {
    flex-grow: 1;
  }
}
</style>
