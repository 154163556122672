import {
  getCurrentEnt, getSessionId, isDef, loopObject,
} from '@/utils';
import axios from 'axios';
import { ElMessage } from 'element-plus';
import { isArray, isPlainObject, uniqueId } from 'lodash';

function message(type, msg) {
  ElMessage({
    showClose: true,
    message: msg,
    type,
  });
}
/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */

const errorHandle = (status, msg) => {
  // 状态码判断
  switch (status) {
    case 400:
      message('error', `请求参数有误(${msg})`);
      break;
    // 401: 未登录状态，跳转登录页
    case 401:
      message('error', msg);
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/console';
      break;
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      message('error', `没有操作权限(${msg})`);
      break;
    // 404请求不存在
    case 404:
      message('error', '请求不存在');
      break;
    case 500:
      message('error', `请求失败，服务器内部错误(${msg})`);
      break;
    case 5000:
      // message('error', msg);
      message('error', msg);
      if (msg === '请重新登录!') {
        localStorage.clear();
        sessionStorage.clear();
        window.location.href = '/console';
      }
      break;
    case 5001:
      message('error', `操作失败(${msg})`);
      break;
    case 504:
      message('error', '与服务器连接失败！');
      break;
      // 2000正确操作，提示成功msg
    case 2000:
      message('success', msg);
      break;
    case 6000:
      // 警告类型 通常是需要二次确认，在具体的接口调用处做处理
      break;
    default:
      message('error', msg);
  }
};
// 创建axios实例
const instance = axios.create({
  timeout: 1000 * 600,
  baseURL: process.env.VUE_APP_URL,
  headers: {
    'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
  },
});
instance.defaults.paramsSerializer = (params) => {
  const parts = [];
  loopObject(params || {}, (val, key) => {
    if (val === null || typeof val === 'undefined') {
      return;
    }
    if (Array.isArray(val)) {
      key = `${key}[]`;
    } else {
      val = [val];
    }

    val.forEach(v => {
      if (v instanceof Date) {
        v = v.toISOString();
      } else if (isPlainObject(v)) {
        v = JSON.stringify(v);
      }
      parts.push(`${key}=${encodeURIComponent(v)}`);
    });
  });
  return parts.join('&');
};
instance.interceptors.request.use((config) => {
  config.uniqueId = uniqueId('axios');

  if (config.data) {
    Object.keys(config.data).forEach((key) => {
      const val = config.data[key];
      if (!isDef(val)) {
        delete config.data[key];
      }
    });
  }

  const newConfig = config;
  const token = getSessionId();
  const ent = getCurrentEnt();

  if (ent && !newConfig.headers.token) {
    newConfig.headers.token = token;
  }
  if (config.method === 'post' && (isPlainObject(config.data) || isArray(config.data))) config.headers['content-type'] = 'application/json';
  return newConfig;
});
// 响应拦截器
instance.interceptors.response.use(
  (response) => {
    const { status, data } = response;
    // if (response.config.url.indexOf('/auth/user/myInfo') >= 0) data.code = 401;
    const { code, msg } = data;
    if (code && code !== 200) {
      errorHandle(code, msg);
      const e = new Error(msg);
      e.code = code;
      e.msg = msg;
      e.response = response;
      return Promise.reject(e);
    }

    if (status === 200) {
      if (typeof response.data !== 'undefined') {
        return Promise.resolve(response);
      }
      return Promise.reject(response);
    }

    return Promise.reject(response);
  },
  (error) => {
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      errorHandle(response.status, response.data.msg);
      return Promise.reject(response);
    }

    // const originalRequest = error.config;
    // && !originalRequest._retry
    if (error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
      // originalRequest._retry = true
      // return axios.request(originalRequest);
      message('error', '请求超时，请稍后重试！');
      return Promise.reject(response);
    }
  },
);

export default instance;
