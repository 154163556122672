import { ElMessage, ElMessageBox } from 'element-plus';
import { cloneDeep, pick, isEmpty } from 'lodash';
import {
  reactive, toRefs, ref, provide, inject, computed,
  nextTick,
} from 'vue';
import { CksError, cksRequestError } from './cks-error';
import { isDef } from './common';
import apis from '@/request/apis';
import errorImg from '@/assets/images/close.png';
// import store from '../store/index';

export const getTime = (date) => {
  if (!date) return '';
  const [str = ''] = date.split(' ');
  return str;
};
/**
 * 图片loading代理
 * @param {String} url 图片地址
 * @param {Boolean} before 图片加载之前开始做什么事
 * @param {Boolean} next 图片加载完成后做什么事
 */
export const proxyImg = (url, before, next) => {
  try {
    const img = new Image();
    before();
    img.src = url;
    img.onload = () => {
      next(url);
    };
    img.onerror = () => {
      next(errorImg);
    };
  } catch {}
};

/**
 * 获取异常信息
 * @param {Error} e 异常实例
 * @param {Boolean} showDetail 是否加入附加异常信息
 */
export function getError(e, showDetail = false) {
  const suffix = (showDetail && e instanceof CksError) ? `\n${JSON.stringify(e.detail, null, 2)}` : '';
  if (e instanceof cksRequestError) {
    return `[${e.code}]${e.message}${suffix}`;
  } if (e instanceof CksError) {
    return `[${e.name}]${e.message}${suffix}`;
  }
  return e.message;
}
/**
 * 展示异常信息
 * @param {Error} e 异常实例
 */
export const showError = (e) => ElMessage({ showClose: true, type: 'error', message: getError(e) });

export const showErrorMsg = (msg) => ElMessage({ showClose: true, type: 'error', message: msg });
/**
 * 获取必填校验规则
 * @param {String} label 字段名
 */
export const getRequireRule = (label) => ({ required: true, message: `${label || ''}不能为空` });
/**
 * 获取浮点数校验规则(保留5位小数)
 */
export const buildUnitRule = () => ({ pattern: /^\d*(\.\d{0,5})?$/, message: '请输入大于0的数字，小数位最多保留5位' });
export const getFloatRule = () => ({ pattern: /^\d*(\.\d{0,5})?$/, message: '请输入大于等于0的数字，最多保留5位小数' });
export const getTwoRule = () => ({ pattern: /^\d*(\.\d{0,2})?$/, message: '请输入大于等于0的数字，最多保留2位小数' });
export const getOneRule = () => ({ pattern: /^\d*(\.\d{0,1})?$/, message: '请输入大于等于0的数字，最多保留1位小数' });
export const getFloatTwoRule = () => ({ pattern: /^([1-9]\d*(\.\d{1,2})?|([0](\.([0][1-9]|[1-9]\d{0,1}))))$/, message: '请输入大于0的数字，最多保留2位小数' });
export const getUseRateRule = () => ({ pattern: /^(0(\.\d{1,2})?|1(\.0{1,2})?)$/, message: '请输入大于0小于等于1的数字，最多保留2位小数' });
export const getPositiveRule = () => ({
  validator(rule, val, next) {
    const checkVal = Number(val);
    if (checkVal > 0) {
      next();
    } else {
      next(new Error('请输入大于0的数字'));
    }
  },
});

export const getThanOrEquaRule = () => ({
  validator(rule, val, next) {
    const checkVal = Number(val);
    if (checkVal >= 0) {
      next();
    } else {
      next(new Error('请输入大于等于0的数字'));
    }
  },
});

/**
 * 获取整数校验规则
 */
export const getIntegerRule = () => ({ pattern: /^\d*$/, message: '请输入大于等于0的整数' });
export const getIntegerRule31 = () => ({ pattern: /^[1-9]$|^[1-2]\d$|^3[0-1]$/, message: '请输入大于0小于等于31的整数' });
export const getIntegerRule1_9 = () => ({ pattern: /^[1-9]$/, message: '请输入1-9之间的正整数' });
export const getRegexpRule = (pattern, message) => ({ pattern, message });
export const getEmailRule = (label) => ({ type: 'email', message: label || '邮箱格式不正确' });
/**
 * 获取整数校验规则
 */
export const getPositiveIntegerRule = () => ({ pattern: /^[1-9]\d*$/, message: '请输入大于0的正整数' });
/**
 * 长度/最大值校验
 * @param {number} max 最大值
 * @param {'string'|'number'|'array'} type 校验类型
 */
export const getMaxLengthRule = (max, type = 'string') => ({
  validator(rule, val, next) {
    let checkResult = false;
    if (type === 'number') {
      checkResult = Number(val) <= max;
    } else if (type === 'array') {
      checkResult = val?.length <= max;
    } else {
      const checkVal = isDef(val) ? String(val) : '';
      let len = checkVal.length;
      for (let i = 0; i < checkVal.length; i++) {
        if (checkVal.charCodeAt(i).toString(16).length >= 4) {
          len++;
        }
      }
      checkResult = len <= max;
    }
    if (checkResult) {
      next();
    } else {
      const specialMsg = {
        number: `请输入小于等于${max}的数字`,
        array: `选项个数不能超过${max}个`,
      };
      next(new Error(specialMsg[type] || `最多输入${max}个字符(一个中文按两个字符计算)`));
    }
  },
});

/**
 * 区间校验
 * @param {number} min 最小值
 * @param {number} max 最大值
 * @param {'string'|'number'|'array'} type 校验类型
 */
export const getLengthRangeRule = (min = 1, max = 17, type = 'string') => ({
  validator(rule, val, next) {
    let checkResult = false;
    if (type === 'number') {
      checkResult = Number(val) <= max && Number(val) >= min;
    } else if (type === 'array') {
      checkResult = val?.length <= max && val?.length >= min;
    } else {
      const checkVal = isDef(val) ? String(val) : '';
      let len = checkVal.length;
      for (let i = 0; i < checkVal.length; i++) {
        if (checkVal.charCodeAt(i).toString(16).length >= 4) {
          len++;
        }
      }
      checkResult = len <= max && len >= min;
    }
    if (checkResult) {
      next();
    } else {
      const specialMsg = {
        number: `请输入${min}到${max}的数字`,
        array: `选项个数应在${min}到${max}个之间`,
      };
      next(new Error(specialMsg[type] || `请输入${min}到${max}个字符(一个中文按两个字符计算)`));
    }
  },
});
/**
 * 断言
 * @param {Boolean} expression 断言值
 * @param {String} msg 提示信息
 * @param  {...any} detail 附加异常信息
 */
export const assert = (expression, msg, ...detail) => {
  if (!expression) {
    throw new CksError(msg, detail);
  }
};
/**
 * 转换字典描述
 * @param {Array|Object} dict 字典
 * @param {*} value 值
 * @param {{ matchKey: string, pickKey: string }} opt 转换配置
 * @returns
 */
export function transferDict(dict = [], value, opt = {}) {
  const { matchKey, pickKey } = { matchKey: 'label', pickKey: 'name', ...opt };
  if (Array.isArray(dict)) {
    return dict.find((item) => item[matchKey] === value)?.[pickKey] ?? '';
  }
  return dict[value]?.[pickKey] ?? dict[value] ?? '';
}

const cacheSessionKey = '__cache__';
/**
 * 缓存会话id
 * @param {String} token
 */
export function setSessionId(token) {
  const cacheVal = { token, expire: Date.now() + 7200000 };
  sessionStorage.setItem(cacheSessionKey, btoa(JSON.stringify(cacheVal)));
}
/**
 * 获取会话id
 * @returns {String}
 */
export function getSessionId() {
  try {
    const cacheVal = sessionStorage.getItem(cacheSessionKey);
    return cacheVal && JSON.parse(atob(cacheVal)).token;
  } catch (e) {
    return '';
  }
}

const cacheEntKey = '__ent__';
/**
 * 缓存当前选择的企业信息
 * @typedef {Object} EntInfo
 * @property {String} entId 企业id/仓库id
 * @property {Boolean} isStorage 是否是仓库
 * @property {String} levelInfo 企业分层信息(暂时兼容旧代码，改版完成后移除)
 * @param {EntInfo} val
 */
export function setCurrentEnt(val) {
  sessionStorage.setItem(cacheEntKey, JSON.stringify(val));
}
/**
 * 获取当前选择的企业信息
 * @returns {EntInfo}
 */
export function getCurrentEnt() {
  try {
    const cacheVal = sessionStorage.getItem(cacheEntKey);
    return cacheVal ? JSON.parse(cacheVal) : {};
  } catch (e) {
    return {};
  }
}
/**
 * 移除企业信息缓存
 */
export function clearCurrentEnt() {
  sessionStorage.removeItem(cacheEntKey);
}

/**
 * 遍历树形结构
 * @param {Array<Object>} treeData 根节点数组
 * @param {String} childKey 子数组属性名
 * @param {(nodeData: any) => boolean} cb 遍历回调函数
 * * @param {boolean} handleBack 是否处理回调的返回值(是否继续遍历子节点)
 */
export function loopTree(treeData, childKey, cb, handleBack) {
  assert(Array.isArray(treeData), 'argument treeData must be Array');
  assert(typeof childKey === 'string', 'argument childKey must be string');
  treeData.forEach((treeNode) => {
    const doCon = cb(treeNode);
    if (!handleBack || doCon) {
      loopTree(treeNode[childKey] || [], childKey, cb);
    }
  });
}

let screenTestDom;
const screenCheckResult = reactive({
  isBaseScreen: false,
  isMediumScreen: false,
  isLargeScreen: false,
  screenSize: 'wsm',
});
/**
 * 检测屏幕尺寸
 * @typedef {Object} CheckResult
 * @property {import('@vue/reactivity').Ref<Boolean>} isBaseScreen
 * @property {import('@vue/reactivity').Ref<Boolean>} isMediumScreen
 * @property {import('@vue/reactivity').Ref<Boolean>} isLargeScreen
 * @property {import('@vue/reactivity').Ref<String>} screenSize
 * @returns {CheckResult}
 */
export function useScreen() {
  function reset() {
    const [wmdDom, wlgDom] = screenTestDom;
    if (window.getComputedStyle(wlgDom)?.display === 'block') {
      screenCheckResult.screenSize = 'wlg';
    } else if (window.getComputedStyle(wmdDom)?.display === 'block') {
      screenCheckResult.screenSize = 'wmd';
    } else {
      screenCheckResult.screenSize = 'wsm';
    }
    const setMap = [
      ['isBaseScreen', 'wsm'],
      ['isMediumScreen', 'wmd'],
      ['isLargeScreen', 'wlg'],
    ];
    setMap.forEach(([prop, size]) => {
      screenCheckResult[prop] = screenCheckResult.screenSize === size;
    });
  }

  if (!screenTestDom) {
    const [wmdDom, wlgDom] = [
      document.createElement('div'),
      document.createElement('div'),
    ];
    screenTestDom = [wmdDom, wlgDom];
    wmdDom.classList.add('show-wmd');
    wlgDom.classList.add('show-wlg');
    screenTestDom.forEach(dom => document.body.appendChild(dom));
    window.addEventListener('resize', reset);
  }

  reset();

  return toRefs(screenCheckResult);
}

/**
 * 遍历对象
 * @param {*} obj 目标对象
 * @param {(val: any, key: string) => void | false} cb 回调函数
 */
export function loopObject(obj, cb) {
  assert(typeof obj === 'object', 'obj must be object');
  const keyVals = Object.entries(obj);
  for (let i = 0; i < keyVals.length; i++) {
    const [key, val] = keyVals[i];
    const res = cb(val, key);
    if (res === false) break;
  }
}

/**
 * 判断日期是否同一天
 * @param {时间对象} firstDate
 *  @param {时间对象} lastDate
 * @returns boolean
 */
export function datesAreOnSameDay(firstDate, lastDate) {
  return firstDate.getFullYear() === lastDate.getFullYear() && firstDate.getMonth() === lastDate.getMonth() && firstDate.getDate() === lastDate.getDate();
}

export function setLocalStorage(key, val) {
  window.localStorage.setItem(key, typeof val === 'object' ? JSON.stringify(val) : val);
}
export function getLocalStorage(key) {
  let val = window.localStorage.getItem(key);
  try {
    val = JSON.parse(val);
  // eslint-disable-next-line
  } catch (e) {
  }
  return val;
}

export function useCache(key) {
  let cacheKey = key;
  const cacheVal = ref(getLocalStorage(key));
  function setKey(newKey) {
    cacheKey = newKey;
    cacheVal.value = getLocalStorage(cacheKey);
  }
  function setVal(val) {
    setLocalStorage(cacheKey, val);
  }
  return {
    val: cacheVal,
    setKey,
    setVal,
  };
}

const historyBack = ref();
export function useHistory() {
  return historyBack;
}
export function setHistoryBack(to) {
  historyBack.value = to;
}

export function createSeriesProperties(prefix, num, defaultVal) {
  const res = {};
  for (let i = 1; i <= num; i++) {
    res[`${prefix}${i}`] = cloneDeep(defaultVal || '');
  }
  return res;
}

export function showSuccess(msg) {
  ElMessage({ showClose: true, type: 'success', message: msg });
}

const pageStoreKey = 'cksPageStore';
export function setPageStore(data) {
  provide(pageStoreKey, data);
}
export function getPageStore() {
  return inject(pageStoreKey);
}

export async function confirm(message) {
  try {
    await ElMessageBox({
      message,
      showCancelButton: true,
      title: '温馨提示',
      type: 'warning',
      showClose: false,
    });
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * 从响应式对象中按key数组提取属性值
 * @param {import('vue').UnwrapRef} reactiveData 响应式对象
 * @param {string[]} refNames
 */
export function pickRefs(reactiveData, refNames) {
  return pick(toRefs(reactiveData), refNames);
}

/**
 * @typedef ListResponse 列表查询返回结构
 * @property {Array<{[key: string]: any}>} records 当前页数据
 * @property {number} total 总数
 *
 * @typedef Pagination 分页参数
 * @property {number} current 当前页
 * @property {number} size 页大小
 *
 * @typedef Options 配置参数
 * @property {(...args) => Promise<ListResponse>} loadApi 查询列表数据api
 * @property {(...args) => Promise} delApi 删除数据api
 * @property {(...args) => Promise} saveApi 新增数据api
 * @property {(...args) => Promise} updateApi 编辑数据api
 * @property {string} delKey 删除时从行数据的delKey中取值
 * @property {string} delParamKey 删除时传递给后台的params中的属性名
 * @property {Array<{[key: string]: any}>} defaultConditions 默认查询条件
 * @property {import('vue').Ref<boolean>} searchable 是否可查询
 * @property {Object<string, any>|(pagination: Pagination) => Object<string, any>} params 查询参数
 *
 * @typedef 删除时定制化需求
 * @typedef filJudgeOpt 删除时判断条件json
 * @property {string} filtrateForDelete 删除时筛选字段名
 * @property {string} judgeMatch 删除时筛选条件
 * @property {string} prompt 删除时不符合要求的提示词
 *
 * @typedef TableProps 表格绑定参数
 * @property {Array<Object<string, any>>} selected 已选表格行
 * @property {boolean} deleting 删除状态
 * @property {boolean} loading 数据加载状态
 * @property {boolean} lockDel 锁定删除按钮
 * @property {import('vue').Ref} table 表格引用
 * @property {import('vue').Ref} customizeCol 自定义列引用
 * @property {boolean} refreshTableHearder 是否页面刷新表头信息
 * @property {function} refreshTableHeaders 刷新表头函数
 * @property {function} handleCustomize 打开自定义列组件
 * @property {function} closeDiago 关闭自定义列组件
 * @property {(selected: any[]) => void} handleSelectionChange 表格选中行变化函数
 * @property {() => Promise} handleDelete 删除函数
 * @property {function} handleSearch 查询函数
 * @property {function} refresh 刷新函数
 * @property {function} loadDetail 获取详情函数
 * @property {function} creatNewRow 新建一行函数
 * @property {function} cancelNewRow 取消新建一行
 * @property {function} saveNewRow 保存数据函数
 * @property {function({pagination: Pagination}, function(Error|ListResponse): void): Promise} load 数据加载函数
 * @property {import('vue').Ref<Array<Object<string, any>>>} tableData 表格数据
 *
 * @param {Options} options 配置参数
 * @returns {import('@vue/reactivity').UnwrapNestedRefs<TableProps>} tableProps 表格相关配置
 */
export function createTableProps(options = {}) {
  const {
    delApi,
    saveApi,
    updateApi,
    loadDetail = '',
    delKey = 'id',
    delParamKey,
    formRef,
    filJudgeOpt = {},
    defaultConditions = [],
    defaultParams = {},
    params = {},
    searchable = ref(true),
    refreshConditions,
    code = '',
    refreshTableHeaders = {},
  } = options;
  let { loadApi } = options;
  const selected = ref([]);
  const selectable = (row, index) => index !== 0;
  const deleting = ref(false);
  const loading = ref(false);
  const lockDel = computed(() => !selected.value.length);
  const table = ref();
  const tableData = ref([]);
  const customizeCol = ref(null);
  const refreshTableHearder = ref(true);

  if (typeof refreshTableHeaders === 'function' && refreshTableHeaders().length === 0 && code) {
    apis.userDefined.getUserSelfColumn({ code }).then((res) => {
      refreshTableHeaders(res);
    });
  }

  const updateLoadApi = (api) => {
    loadApi = api;
  };

  return reactive({
    updateLoadApi,
    selectable,
    selected,
    deleting,
    loading,
    lockDel,
    table,
    tableData,
    customizeCol,
    refreshTableHearder,
    setStyle({ row }) {
      const flag = selected.value.some(item => item === row);
      if (flag) {
        return { backgroundColor: '#EAF8EF' };
      }
      return {};
    },

    handleSelectionChange(rows) {
      selected.value = rows;
      // 判断第一行是否是空
      if (rows.length > 0 && isEmpty(rows[0])) {
        rows.shift();
      }
      selected.value = rows;
      // selected.value = store.state.selectTableList;
    },
    async handleDelete() {
      // 增加当选中行里面key值有isNewRow且isNewRow为true时提示新建行状态下不能删除
      const isNewRow = selected.value.some(item => !!item.isNewRow);
      if (isNewRow) {
        return showErrorMsg('新建行状态下不能删除');
      }
      if (filJudgeOpt.filtrateForDelete) {
        const status = selected.value.some(item => (item[filJudgeOpt.filtrateForDelete] && item[filJudgeOpt.filtrateForDelete] !== filJudgeOpt.judgeMatch));
        if (status) {
          return showErrorMsg(filJudgeOpt.prompt);
        }
      }
      if (await confirm('请确认是否删除?')) {
        try {
          deleting.value = true;
          let delParams = [];
          if (typeof delKey === 'string') {
            const delKeyVals = delKey ? selected.value.map(item => item[delKey]).join(',') : selected.value;
            delParams = delParamKey ? { [delParamKey]: delKeyVals } : delKeyVals;
          } else {
            selected.value.map(item => {
              const tempValue = {};
              delKey.map(val => {
                tempValue[val] = item[val];
              });
              delParams.push(tempValue);
            });
          }

          try {
            await delApi(delParams);
            showSuccess('删除成功');
            // store.commit('updateSelectTableList', []);
          } catch (e) {
            deleting.value = false;
          }
          table.value?.refresh();
          if (typeof loadDetail === 'function') {
            loadDetail();
          }
        } finally {
          deleting.value = false;
        }
      }
    },
    handleSearch() {
      searchable.value && table.value?.search();
    },
    refresh() {
      searchable.value && table.value?.refresh();
      if (typeof loadDetail === 'function') {
        loadDetail();
      }
    },
    creatNewRow(defaultRows = {}) {
      defaultRows = JSON.parse(JSON.stringify(defaultRows));
      defaultRows.isEdit = true;
      defaultRows.isNewRow = true;
      const hasNewRow = tableData.value.find(item => item.isNewRow);
      if (hasNewRow) {
        return showErrorMsg('您有新建行没有保存');
      }
      // if (tableData.value.length > 0) {
      //   const obj = {};
      //   Object.keys(tableData.value[tableData.value.length - 1]).forEach(key => {
      //     obj[key] = '';
      //   });
      //   tableData.value.push(Object.assign(obj, defaultRows));
      // } else {
      // tableData.value.push(defaultRows);
      // }
      tableData.value.unshift(defaultRows);
      // if (this.disableForAllTableSearch) {
      //   table.value.$refs.tableRef.rows.unshift(defaultRows);
      // } else {
      table.value.$refs.tableRef.rows.splice(1, 0, defaultRows);
      // }
    },
    cancelNewRow() {
      searchable.value && table.value?.refresh();
      if (typeof loadDetail === 'function') {
        loadDetail();
      }
    },
    async saveRow(row, next) {
      try { await formRef().formRef?.validate(); } catch (e) {
        return;
      }
      if (!row) {
        return;
      }
      if (!row.isNewRow) {
        if (updateApi) {
          await updateApi(row);
        } else {
          await saveApi(row);
        }
      } else {
        await saveApi(row);
      }

      searchable.value && table.value?.refresh();
      if (typeof loadDetail === 'function') {
        loadDetail();
      }
      next();
      showSuccess('保存成功');
    },
    async load({ pagination }, next) {
      try {
        loading.value = true;
        const searchParams = typeof params === 'function' ? params(pagination) : params;
        let searchConditions = typeof defaultConditions === 'function' ? defaultConditions() : defaultConditions;
        if (searchParams.conditions && typeof searchParams.conditions === 'string') {
          searchConditions = searchConditions.concat(JSON.parse(searchParams.conditions));
        }
        searchParams.conditions = JSON.stringify(searchConditions);
        const res = await loadApi({ ...pagination, ...searchParams, ...(typeof defaultParams === 'function' ? defaultParams() : defaultParams) });
        tableData.value = res?.records || [];
        nextTick(() => next(res));
      } catch (e) {
        next(e);
      } finally {
        loading.value = false;
      }
    },
    refreshActive() {
      table.value?.refresh();
      if (typeof loadDetail === 'function') {
        loadDetail();
      }
    },
    refreshConditions() {
      if (typeof refreshConditions === 'function') {
        refreshConditions();
      }
    },

    handleCustomize() {
      customizeCol.value?.handleCustomize(true);
    },

    closeDiago(val) {
      refreshTableHeaders(val);
      refreshTableHearder.value = false;
      nextTick(() => {
        refreshTableHearder.value = true;
      });
    },
  });
}

/**
 * 将工具类绑定值vue实例(追加「 $ 」前缀)
 * @param {import('vue').App} app vue实例
 */
export default function bindApp(app) {
  app.config.globalProperties.$getError = getError;
  app.config.globalProperties.$showError = showError;
  app.config.globalProperties.$getRequireRule = getRequireRule;
  app.config.globalProperties.$getFloatRule = getFloatRule;
  app.config.globalProperties.$getFloatTwoRule = getFloatTwoRule;
  app.config.globalProperties.$getIntegerRule = getIntegerRule;
  app.config.globalProperties.$getPositiveIntegerRule = getPositiveIntegerRule;
  app.config.globalProperties.$transferDict = transferDict;
  app.config.globalProperties.$useScreen = useScreen;
}

export * from './form-util';
export * from './common';
export * from './date-util';
