<template>
  <div class="w footer-box">
    <div class="footer-left">
      <div class="information">
        <div class="contact">
          <div class="attract-investment">
            <div class="icon">
              <img src="@/assets/images/home-image/page30.png" alt="">
            </div>
            <span>{{ address }}</span>
          </div>
          <div class="contact-phone">{{ phone }}</div>
        </div>
        <div class="phone">
          <div class="address">
            <div class="icon">
              <img src="@/assets/images/home-image/page31.png" alt="">
            </div>
            <span>地址</span>
          </div>
          <div class="address-detail">深圳市宝安区航城街道恒丰工业城B9栋1层</div>
        </div>
      </div>
      <div class="copyright">
        <div class="title">穗深冷链 版权所有</div>
        <div class="title">版本号：SSCOOLER-1.1.4.1.20240319.1</div>
        <div class="b">备案号：<a style="color: #FFFFFF;" target="_blank" href="https://beian.miit.gov.cn">粤ICP备2021168587号</a></div>
      </div>
    </div>
    <div class="footer-right">
      <div class="img-box">
        <img src="@/assets/images/home-image/erweima.jpg" alt="">
      </div>
      <span>扫码关注公众号</span>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, watch } from 'vue';
import { useRoute } from 'vue-router';

export default {
  setup() {
    const route = useRoute();
    const data = reactive({
      phone: '0755-82263776',
      address: '全国招商热线',
    });
    watch(() => route, newVal => {
      if (newVal) {
        const { meta } = newVal;
        data.phone = meta.phone || '0755-82263776';
        data.address = meta.address || '全国招商热线';
      }
    }, { deep: true });
    return {
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.footer-box {
  display: flex;
  justify-content: space-between;
  height: 15.625vw;
}
.footer-left {
  font-size: 0.99vw;
  color: #FFFFFF;
  .information {
    display: flex;
    margin-bottom: 1.875vw;
    margin-top: 3.958vw;
    .contact-phone {
      font-size: 1.563vw;
      line-height: 1.667vw;
      font-weight: bold;
      color: #FFFFFF;
    }
    .attract-investment {
      margin-bottom: 1.25vw;
      display: flex;
      align-items: center;

      .icon {
        width: 1.042vw;
        height: 1.042vw;
        margin-right: 0.521vw;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
    .address-detail {
      font-size: 1.042vw;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 1.667vw;
    }
  }
  .phone {
    margin-left: 4.167vw;
    .address {
      margin-bottom: 1.25vw;
      display: flex;
      align-items: center;
    }
    .icon {
      width: 0.729vw;
      height: 0.938vw;
      margin-right: 0.521vw;
      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }
  .copyright {
    font-size: 0.833vw;
    font-weight: 100;
    color: #FFFFFF;
    .title {
      margin-bottom: 0.417vw;
    }
    .b {
      display: flex;
      // justify-content: center;
    }
  }
}
.footer-right {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3.021vw;
  .img-box {
    width: 8.49vw;
    height: 8.49vw;
    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  &>span {
    font-size: 0.833vw;
    color: #FFFFFF;
    margin-top: 0.417vw;
  }
}
@media screen and (max-width: 768px) {
  .footer-box {
    display: flex;
    justify-content: space-between;
    height: auto;
    flex-direction: column;
  }
  .footer-left {
    font-size: 0.99vw;
    color: #FFFFFF;
    .information {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.875vw;
      margin-top: 3.958vw;
      .contact-phone {
        font-size: 1.563vw;
        line-height: 2.667vw;
        font-weight: bold;
        color: #FFFFFF;
        display: flex;
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        margin-top: 1.333vw;
      }
      .attract-investment {
        margin-bottom: 1.25vw;
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;

        .icon {
          width: 2.667vw;
          height: 2.667vw;
          margin-right: 1.333vw;
          img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: cover;
          }
        }
      }
      .address-detail {
        font-size: 1.867vw;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 1.667vw;
        margin-top: 1.333vw;
      }
    }
    .phone {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-left: 4.167vw;
      .address {
        margin-bottom: 1.25vw;
        display: flex;
        align-items: center;
        margin-top: 1.333vw;

      }
      .icon {
        width: 2.667vw;
        height: 2.667vw;
        margin-right:1.333vw;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
    .copyright {
      font-size: 0.833vw;
      font-weight: 100;
      color: #FFFFFF;
      margin-top: 1.333vw;

      .title {
        margin-bottom: 0.417vw;
        text-align: center;
      }
      .b {
        display: flex;
        justify-content: center;
      }
    }
  }
  .footer-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3.021vw;
    .img-box {
      width: 40vw;
      height: 40vw;
      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: cover;
      }
    }
    &>span {
      font-size: 1.867vw;
      color: #FFFFFF;
      margin-top: 1.333vw;
    }
  }
}
</style>
