<template>
  <div :class="['cks-page-module', border && 'is-border']" :style="style">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'CksPageModule',
  desc: '页面模块',
  props: {
    border: {
      type: Boolean,
      default: false,
    },
    style: {
      type: String,
      default: 'background-color: #F8F8F8;',
    },
  },
};
</script>

<style lang="scss" scoped>
.cks-page-module {
  width: 100%;
  &.is-border {
    border: 1px solid #cccccc;
  }
}
</style>
