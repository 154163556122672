<template>
  <cks-search
    :modelValue="form"
    @update:modelValue="handleChange"
    :options="options"
    v-bind="$attrs"
  ></cks-search>
</template>

<script>
import {
  reactive, toRefs, watchEffect,
} from 'vue';
import { notEmpty } from '@/utils';

export default {
  name: 'cksCondition',
  desc: '查询控件(适配condition转换)',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: [Array, String],
      default: () => [],
    },
    stringify: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      form: {},
    });
    watchEffect(() => {
      let conditions = props.modelValue;
      if (props.stringify && typeof conditions === 'string') {
        conditions = JSON.parse(conditions);
      }
      data.form = conditions.reduce((res, item) => {
        if (item.opr === 'between' && notEmpty(item.value)) {
          res[item.name] = item.value.split('-');
        } else if (item.opr === 'in' && notEmpty(item.value)) {
          res[item.name] = item.value.split(',');
        } else {
          res[item.name] = item.value;
        }
        return res;
      }, {});
    });
    function handleChange(form) {
      data.form = form;
      const conditions = [];
      props.options.forEach((option) => {
        const { prop, op } = option;
        let formVal = form[prop];
        let symbol = null;
        if (formVal) {
          symbol = form[`${prop}.symbol`];
        }
        if (notEmpty(formVal)) {
          if (op === 'between' && Array.isArray(formVal)) {
            formVal = formVal.join('-');
          } else if (op === 'in' && Array.isArray(formVal)) {
            formVal = formVal.join(',');
          }
          conditions.push({
            name: prop,
            opr: symbol || op,
            value: formVal,
          });
        }
      });
      emit('update:modelValue', props.stringify ? JSON.stringify(conditions) : conditions);
    }

    return {
      handleChange,
      ...toRefs(data),
    };
  },
};
</script>

<style lang="scss" scoped>
.cks-search {
  .cks-search-conditions {
    max-height: 146px;
    overflow: hidden;
  }
  .cks-search-btns {
    text-align: center;
  }
  &.is-grow {
    .cks-search-conditions {
      max-height: auto;
      overflow: auto;
    }
  }
  :deep {
    .el-form-item {
      margin-bottom: 16px;
    }
    .el-form-item__label,
    .el-form--label-top .el-form-item__label {
      padding-bottom: 0;
      line-height: 24px;
    }
  }
}
</style>
