<template>
    <el-pagination
      :class="['my-pagination', needWidth && 'my-needWidth']"
      @size-change="handelSortChange"
      @current-change="handelSortChange"
      v-model:current-page="pagination.current"
      v-model:page-size="pagination.size"
      layout="->, prev, pager, next"
      :total="total"
    ></el-pagination>
</template>

<script>
import {
  reactive, toRefs, watchEffect,
} from 'vue';

export default {
  name: 'Pagination',
  desc: '分页模块',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Object,
      default: () => ({
        current: 1,
        size: 10,
      }),
    },
    total: {
      type: String || Number,
      default: 0,
    },
    needWidth: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      pagination: {
        current: 1,
        size: 10,
      },
    });

    watchEffect(() => {
      data.pagination = props.modelValue;
    });

    function handelSortChange() {
      data.pagination.current = Math.floor(data.pagination.current);
      emit('update:modelValue', data.pagination);
    }

    return {
      ...toRefs(data),
      handelSortChange,
    };
  },
};
</script>

<style lang="scss" scoped>
  .my-pagination{
    font-size: .18rem;
    display: flex;
    justify-content: center;
    :deep .el-pager li.active {
      border-radius: 4px;
      color: #fff;
      background: var(--baseColor1) !important;
    }
    :deep .el-pager li {
      border-radius: 2px;
      color: #A8A8A8;
      background: #ECECEC !important;
      // margin: 0px .1rem;
      // font-size: .18rem !important;
    }
  }

  .my-needWidth{
    // width: 1200px;
    // min-width: 1200px;
    padding:0 20px;
    margin: 0 auto;
  }
</style>
