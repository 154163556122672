import { createStandardRequest, createPostQueryRequest } from '../helper';

export default {
  save: createStandardRequest('/back/courtship/info/save', 'post'),
  getDetail: createPostQueryRequest('/back/courtship/info/getDetail'),
  list: createPostQueryRequest('/back/courtship/info/getPage'),
  changeStatus: createStandardRequest('back/courtship/info/changeStatus', 'get'),
  up: createPostQueryRequest('/back/courtship/info/up', 'post'),
  down: createPostQueryRequest('/back/courtship/info/down', 'post'),
  first: createPostQueryRequest('/back/courtship/info/first', 'post'),
  last: createPostQueryRequest('/back/courtship/info//last', 'post'),
};
