<template>
  <el-upload
    v-if="!videoUrl"
    v-model:file-list="fileList"
    action="action"
    :http-request="uploadFile"
    list-type="picture-card"
    :on-preview="handlePictureCardPreview"
    :on-remove="handleRemove"
    :before-upload="beforeUpload"
    class="fdiid"
    :class="{'active-img-list': fileList.length >= limit}"
    :limit="1"
    v-loading="loading"
  >
    <el-icon><Plus /></el-icon>
  </el-upload>
  <div class="video-box" v-else v-loading="loading">
    <video controls class="" ref="videoRef" :src="videoUrl"></video>
    <div @click="deleteUrl" class="close curr">
      <el-icon><Delete/></el-icon>
    </div>
  </div>
  <div class="line">
    <el-link :disabled="loading" v-if="fileUrl" @click="handlerVideo(fileUrl, true)" class="line" type="primary">
      刷新视频<el-icon><Refresh /></el-icon>
    </el-link>
    <span v-show="!!msg">({{ msg }})</span>
  </div>
  <el-dialog v-model="dialogVisible" width="30%">
    <div style="width: 100%; margin: 0 auto;">
      <img style="width:100%;" :src="dialogImageUrl" alt="Preview Image" />
    </div>
  </el-dialog>
</template>

<script>
import {
  nextTick, onBeforeUnmount, reactive, toRefs, watchEffect,
} from 'vue';
import { Plus, Delete, Refresh } from '@element-plus/icons-vue';
import apis from '@/request/apis';
import { showErrorMsg } from '@/utils';
// import Hls from '@/utils/hls';
import Hls from 'hls.js';

export default {
  props: {
    limit: { // 最多个数
      type: Number,
      default: 1,
    },
    fileUrl: {
      type: String,
      default: '',
    },
    size: { // 文件最大大小
      type: Number,
      default: 300,
    },
  },
  components: {
    Plus,
    Delete,
    Refresh,
  },
  setup(props, { emit }) {
    const data = reactive({
      loading: false,
      dialogImageUrl: '',
      videoUrl: '',
      dialogVisible: false,
      fileList: [],
      videoRef: null,
      loadingNum: 0,
      msg: '',
    });

    function handleRemove(val) {
      data.fileList = data.fileList.filter(item => item.url !== val.url);
      const url = data.fileList.map(item => item.url).join(',');
      data.videoUrl = url;
      emit('updateUrl', url);
    }
    function handlePictureCardPreview(val) {
      data.dialogImageUrl = val.url;
      data.dialogVisible = true;
    }

    const step = (timer) => new Promise(resolve => {
      setTimeout(() => {
        resolve();
      }, timer);
    });

    let getM3U8 = async (id) => {
      data.loadingNum++;
      if (data.loadingNum === 5) {
        // showSuccess('视频上传成功, 后台在进行处理, 可以先保存其他信息');
        data.msg = '视频上传成功, 后台在进行处理, 可以先保存其他信息';
        data.videoUrl = id;
        data.loadingNum = 0;
        return false;
      }
      emit('updateUrl', id);
      try {
        let res = await apis.upload.getM3U8({
          annexId: id,
        });
        if (res) {
          res = res.replace('/www/video/', '/');
          data.msg = '';
          const hls = new Hls();
          hls.loadSource(res);
          hls.attachMedia(data.videoRef);
          data.fileList.push({
            url: res,
            name: String(Date.now()),
          });
          const url = data.fileList.map(item => item.url).join(',');
          data.videoUrl = url;
          emit('updateUrl', id);
        } else {
          await step(2000);
          await getM3U8(id);
        }
      } catch {
        data.fileList = [];
      } finally {
        data.loading = false;
      }
    };
    watchEffect(() => {
      if (data.msg && data.msg.indexOf('.m3u8') !== -1) {
        data.msg = '';
      }
    });
    const uploadFile = async (val) => {
      const { file } = val;
      try {
        data.loading = true;
        const fd = new FormData();
        fd.append('file', file);
        const res = await apis.upload.uploadvideo(fd);
        await getM3U8(res.id);
      } catch (err) {
        data.fileList = [];
      } finally {
        data.loading = false;
      }
    };
    const beforeUpload = (file) => {
      const { size, type } = file;
      const typeImg = [
        'video/mp4',
      ];
      if (!typeImg.includes(type)) {
        showErrorMsg('视频格式支持.mp4');
        return false;
      }
      if (size > 1024 * 1024 * props.size) {
        showErrorMsg(`大小不能超过${props.size}M`);
        return false;
      }
      return true;
    };

    let handlerVideo = async (id, flag) => {
      let res = await apis.upload.getM3U8({
        annexId: id,
      });
      if (res) {
        res = res.replace('/www/video/', '/');
        data.videoUrl = res;
        data.loading = false;
        data.msg = '';
        nextTick(() => {
          if (!data.videoRef) return;
          const hls = new Hls();
          hls.loadSource(data.videoUrl);
          hls.attachMedia(data.videoRef);
          hls.on(Hls.Events.MANIFEST_PARSED, () => {});
        });
      }
      if (!res && flag) {
        data.loadingNum++;
        if (data.loadingNum === 5) {
          // showSuccess('后台正在解析视频, 请稍后试试');
          data.msg = '视频上传成功, 后台正在解析视频';
          data.loadingNum = 0;
          emit('updateUrl', id);
          data.loading = false;
          return false;
        }
        data.loading = true;
        await step(2000);
        handlerVideo(id, true);
      }
    };

    watchEffect(async () => {
      if (props.fileUrl) {
        data.videoUrl = props.fileUrl;
        // emit('updateUrl', props.fileUrl);
        await handlerVideo(props.fileUrl, true);
      } else {
        data.videoUrl = '';
        data.fileList = [];
      }
    });
    onBeforeUnmount(() => {
      getM3U8 = () => {};
      handlerVideo = () => {};
    });

    const deleteUrl = () => {
      emit('deleteUrl');
    };
    return {
      ...toRefs(data),
      handleRemove,
      handlePictureCardPreview,
      uploadFile,
      deleteUrl,
      beforeUpload,
      handlerVideo,
    };
  },
};
</script>

<style scoped lang="scss">
::v-deep.active-img-list .el-upload {
  display: none;
}
.video-box {
  width: 300px;
  height: 200px;
  border: 1px solid #ccc;
  position: relative;
  position: relative;
  video {
    width: 100%;
    height: 100%;
  }
  .close {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 0px;
    // top: 0;
    bottom: 0;
    font-size: 20px;
    color: red;
    text-align: center;
    line-height: 20px;

    .el-icon {
      font-size: 20px;
    }
  }
}
.line {
  display: flex;
  align-items: center;
  &>span {
    margin-left: 5px;
    color: #bbb;
    font-size: 12px;
  }
}
::v-deep .el-upload--picture-card:hover {
  border: 1px dashed #2F51FF;
}
::v-deep .el-link--inner {
  display: flex;
  align-items: center;
}
</style>
