<template>
  <template v-for="item in tableHeaders" :key="item.prop">
    <el-table-column :label="item.label" :prop="item.prop" v-if="item.selected" show-overflow-tooltip
      :min-width="tableOptions?.[item.prop]?.symbol ? '200px' : tableOptions?.[item.prop]?.minWidth || minWidth"
      :sortable="sortable"
      :fixed="tableOptions?.[item.prop]?.fixed ? 'right':false">
      <!-- <template #header>
        {{ item.label }}
        <cks-table-filter
          :currentCol="item"
          :tableProp="(tableOptions?.[item.prop] && tableOptions?.[item.prop]?.editTrasferKey) || item.prop"
          :options="searchOptions"
          v-bind="$attrs"
          v-if="(tableOptions?.[item.prop] && tableOptions?.[item.prop]?.disableForTableSearch || disableForAllTableSearch) ? false : true"
        >
        </cks-table-filter>
      </template> -->
      <template #default="scope">
        <template v-if="scope.$index===0 && !disableForAllTableSearch">
          <span v-if="!(tableOptions[item.prop] && tableOptions[item.prop].disableForTableSearch)">
              <cks-table-filter
              :currentCol="item"
              :tableProp="(tableOptions?.[item.prop] && tableOptions?.[item.prop]?.editTrasferKey) || item.prop"
              :options="searchOptions"
              v-bind="$attrs"
              v-if="(tableOptions?.[item.prop] && tableOptions?.[item.prop]?.disableForTableSearch || disableForAllTableSearch) ? false : true"
            ></cks-table-filter>
          </span>
          <cks-span v-else></cks-span>
        </template>
        <template v-else>
          <template v-if="scope.row.isEdit">
            <template v-if="(tableOptions?.[item.prop] && tableOptions?.[item.prop]?.editTrasferKey)">
                 <el-form-item :prop="tableOptions?.[item.prop]?.editTrasferKey">
                    <cks-select
                        v-model="rows[formId][tableOptions?.[item.prop]?.editTrasferKey]"
                        :options="tableOptions?.[item.prop]?.dict().value.options"
                        :disabled="!rows[formId].isNewRow && tableOptions?.[item.prop]?.disabledKey && tableOptions?.[item.prop]?.disabledKey === 'isNewRow'"
                    ></cks-select>
                </el-form-item>
            </template>
            <template v-else-if="tableOptions?.[item.prop] && tableOptions?.[item.prop]?.isDateKey">
                 <el-form-item :prop="item.prop">
                    <el-date-picker
                        type="date"
                        placeholder="选择日期"
                        value-format='YYYYMMDD'
                        v-model='rows[formId][item.prop]'
                        :disabled="(!rows[formId].isNewRow && tableOptions?.[item.prop]?.disabledKey &&  tableOptions?.[item.prop]?.disabledKey === 'isNewRow')|| (tableOptions?.[item.prop]?.disabledKey &&  tableOptions?.[item.prop]?.disabledKey === 'all')"
                    >
                    </el-date-picker>
                </el-form-item>
            </template>
            <template v-else>
                 <el-form-item :prop="item.prop">
                    <el-input
                        v-model="rows[formId][item.prop]"
                        :disabled="(!rows[formId].isNewRow && tableOptions?.[item.prop]?.disabledKey &&  tableOptions?.[item.prop]?.disabledKey === 'isNewRow')|| (tableOptions?.[item.prop]?.disabledKey &&  tableOptions?.[item.prop]?.disabledKey === 'all')"
                    ></el-input>
                </el-form-item>
            </template>
        </template>
        <template v-else>
            <template v-if="scope.row.isEdit">
              <template v-if="(tableOptions?.[item.prop] && tableOptions?.[item.prop]?.editTrasferKey)">
                  <el-form-item :prop="tableOptions?.[item.prop]?.editTrasferKey">
                      <cks-select
                          v-model="rows[formId][tableOptions?.[item.prop]?.editTrasferKey]"
                          :options="tableOptions?.[item.prop]?.dict().value.options"
                          :disabled="!rows[formId].isNewRow && tableOptions?.[item.prop]?.disabledKey && tableOptions?.[item.prop]?.disabledKey === 'isNewRow'"
                      ></cks-select>
                  </el-form-item>
              </template>
              <template v-else-if="tableOptions?.[item.prop] && tableOptions?.[item.prop]?.isDateKey">
                  <el-form-item :prop="item.prop">
                      <el-date-picker
                          type="date"
                          placeholder="选择日期"
                          value-format='YYYYMMDD'
                          v-model='rows[formId][item.prop]'
                      >
                      </el-date-picker>
                  </el-form-item>
              </template>
              <template v-else>
                  <el-form-item :prop="item.prop">
                      <el-input
                          v-model="rows[formId][item.prop]"
                          :disabled="(!rows[formId].isNewRow && tableOptions?.[item.prop]?.disabledKey &&  tableOptions?.[item.prop]?.disabledKey === 'isNewRow')|| (tableOptions?.[item.prop]?.disabledKey &&  tableOptions?.[item.prop]?.disabledKey === 'all')"
                      ></el-input>
                  </el-form-item>
              </template>
          </template>
          <template v-else>
            <template v-if="tableOptions[item.prop] && tableOptions[item.prop].isSlotKey">
              <slot :name="item.prop"  :row="scope.row" :index="scope.$index"></slot>
          </template>
            <cks-link
              v-else-if="item.required"
              type="primary"
              @click="goToDetail(scope.row)"
              >{{ scope.row[item.prop] }}</cks-link>
            <span v-else>{{ scope.row[item.prop] }}</span>
          </template>
        </template>
        </template>
      </template>
    </el-table-column>
  </template>
</template>

<script>
import {
  reactive, toRefs, watchEffect,
} from 'vue';
import {
  getLikeQueryOption,
  getDictSelectQueryOption,
  getEqQueryOption,
  getDateRangeQueryOption,
} from '@/utils';

export default {
  name: 'cksTableColumn',
  desc: '表格行',
  props: {
    minWidth: {
      props: String,
      default: '180px',
    },
    tableHeaders: {
      type: Array,
      default() {
        return [];
      },
    },
    /**
     * 如果没有编辑状态可以不传
     */
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    /**
     * tableOptions 配置项
     * tableOptions第一层key：表头prop
     * tableOptions第二层
     * {boolean} disableForTableSearch 该表头是否可筛选
     * {boolean} isDateKey 表头中是否为日期筛选
     * {boolean} isSlotKey 表头中是否为插槽
     * {boolean | string} fixed 该列是否可固定
     * {boolean} eq 表头中是否为数值型（为数值型则筛选条件为eq）
     * {string} editTrasferKey 表头中需要转化的key值（用于下拉框）
     * {function} dict 表头中下拉框选项
     * {string} disabledKey 是否让该字段可编辑  all---都不让编辑   isNewRow---新增时可编辑
     * {string} symbol 数字类型的选项符号 初始化需要选择其中一个['=', '>', '<', '>=', '<=']
     */
    tableOptions: {
      type: Object,
      default() {
        return {};
      },
    },
    /**
     * 是否屏蔽全部表头搜索
     */
    disableForAllTableSearch: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: [Boolean, String],
      default: 'custom',
    },
  },
  setup(props, { emit }) {
    const data = reactive({
      formId: 0,
      rows: props.tableData,
      searchOptions: [],
    });
    watchEffect(() => {
      data.rows = props.tableData;
      data.formId = props.tableData.findIndex(item => !!item.isEdit) || 0;
    });

    watchEffect(() => {
      data.searchOptions = [];
      props.tableHeaders.forEach(item => {
        if (props.tableOptions?.[item.prop] && props.tableOptions?.[item.prop]?.editTrasferKey) {
          data.searchOptions.push(getDictSelectQueryOption(props.tableOptions?.[item.prop]?.dict(), props.tableOptions?.[item.prop]?.editTrasferKey, item.label));
        } else if (props.tableOptions?.[item.prop] && props.tableOptions?.[item.prop]?.disableForTableSearch) {
          // 表头查询key值相同的不用
        } else if (props.tableOptions?.[item.prop] && props.tableOptions?.[item.prop]?.eq) {
          data.searchOptions.push(getEqQueryOption(item.prop, item.label));
        } else if (props.tableOptions?.[item.prop] && props.tableOptions?.[item.prop]?.symbol) {
          data.searchOptions.push(getEqQueryOption(item.prop, item.label, null, { symbol: props.tableOptions?.[item.prop].symbol, op: props.tableOptions?.[item.prop].symbol }));
        } else if (props.tableOptions?.[item.prop] && props.tableOptions?.[item.prop]?.isDateKey) {
          data.searchOptions.push(getDateRangeQueryOption(item.prop, item.label));
        } else {
          data.searchOptions.push(getLikeQueryOption(item.prop, item.label));
        }
      });
    });

    function goToDetail(row) {
      emit('changeRouter', row);
    }

    return {
      goToDetail,
      ...toRefs(data),
    };
  },
};
</script>
